import APIService from "../api/public-profile-api";
import { fromAPIToModel } from "../models";
import { handleError } from "../../shared/utils/service-utils";

const PublicProfileService = (() => {
  const fetchPublicProfile = async (userId: string) => {
    try {
      const response = await APIService.API.getProfileForNeed(userId);
      if (response?.data) {
        return {
          [userId]: fromAPIToModel.PublicProfileNeedToModel(response.data),
        };
      }
      return {};
    } catch (err) {
      return handleError(err);
    }
  };

  return {
    fetchPublicProfile,
  };
})();

export default PublicProfileService;
