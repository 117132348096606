// eslint-disable-next-line prodigal-son/no-use-mapped-state
import { useMappedState } from "redux-react-hook";
import { getSelectedState } from "@ps/utils";
import { useCallback } from "react";
import { NAMESPACE } from "../shared/constants";

// eslint-disable-next-line
function useMappedStateSelector(getMyMapState, selector = NAMESPACE): any {
  // eslint-disable-next-line prodigal-son/no-use-mapped-state
  return useMappedState(
    useCallback(
      (state) => getSelectedState(state, getMyMapState, selector),
      [getMyMapState, selector],
    ),
  );
}

export default useMappedStateSelector;
