import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "redux-react-hook";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingMainPage, PageFolder, PagePin } from "@ps/ui-components";
import { PROPOSAL_PUBLIC_FOLDER } from "../shared/data-cy";
import { AUTHORIZATION } from "../public-folder-domain/constants";
import {
  fetchPublicFolderError,
  fetchPublicFolderStart,
  fetchPublicFolderSuccess,
} from "../public-folder-domain/store/actions";
import PublicFolderService from "../public-folder-domain/services/publicFolder";
import setPINAuth from "./setPINAuth";
import { useMappedStateSelector } from "../hooks";
import {
  AppContainerMapState,
  AppContainerMapStateReturn,
  AppContainerProps,
} from "./types";
import Page404 from "./page-404";

const AppContainer = ({ children }: AppContainerProps): ReactElement => {
  const [authorized, setAuthorized] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFolderPathNotFound, setIsFolderPathNotFound] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation<{ pathname?: string }>();
  const pathSections = pathname.split("/");
  const publicFolderId = pathSections.slice(-1).toString();

  const mapState = (
    state: AppContainerMapState,
  ): AppContainerMapStateReturn => ({
    isAuthorized: state.credentials?.username && state.credentials?.password,
  });

  const { isAuthorized } = useMappedStateSelector(mapState);

  useEffect(() => {
    const UIDRegexp = new RegExp(
      "[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}",
    );
    if (UIDRegexp.test(pathname)) setIsFolderPathNotFound(false);
    else setIsFolderPathNotFound(true);
  }, []);

  useEffect(() => {
    if (!authorized) {
      const pathnameArr = pathname?.trim().split("/");
      const result = pathnameArr.find((item) => !!item);
      history.push(`/${result}` || "/");
    }
  }, [isAuthorized]);

  const checkIfPinCorrect = async (value: string) => {
    if (value.length >= 0 && value.length <= 3) return setIsError(false);
    setPINAuth({ username: publicFolderId, password: value });
    dispatch(fetchPublicFolderStart());

    const response = await PublicFolderService.fetchPublicFolder(
      publicFolderId,
      value,
    );
    if (response?.id) {
      setAuthorized(true);
      dispatch(fetchPublicFolderSuccess(response));
      return setIsError(false);
    }
    dispatch(fetchPublicFolderError(response));
    return setIsError(true);
  };

  if (pathname === "/")
    return (
      <LoadingMainPage additionalClassName="bg-neutralPrimary-85 pb-12 mt-24 lg:mt-40" />
    );

  if (isFolderPathNotFound) return <Page404 />;

  return authorized ? (
    <PageFolder
      dataCy={`${PROPOSAL_PUBLIC_FOLDER}_folder`}
      onClick={
        pathname.includes("candidate") ? () => history.goBack() : undefined
      }
    >
      {children}
    </PageFolder>
  ) : (
    <PagePin
      dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${AUTHORIZATION}`}
      onPinChange={(value) => checkIfPinCorrect(value)}
      hasError={isError}
    />
  );
};

export default AppContainer;
