import { ReactElement } from "react";
import { useDispatch } from "redux-react-hook";
import { AnyAction } from "redux";
import { Checkbox } from "@ps/ui-components";
import { useMappedStateSelector } from "../../../hooks";
import { PROPOSAL_PUBLIC_FOLDER } from "../../../shared/data-cy";
import { filterCandidatesByProperty } from "../../store/actions";
import { DictionaryEntryModel } from "../../../dictionaries";
import { FilterOptionsMapState, FilterOptionsProps } from "../types";

const FilterOptions = ({
  options,
  field,
  dict,
}: FilterOptionsProps): ReactElement => {
  const dispatch = useDispatch();

  const mapState = (state: FilterOptionsMapState): FilterOptionsMapState => ({
    filters: state.filters,
  });

  const { filters } = useMappedStateSelector(mapState);

  return options?.length ? (
    <div className="flex flex-col gap-2">
      {options.map((option: string): ReactElement => {
        const isChecked: boolean = filters[field].includes(option);

        return (
          <div
            className="flex items-center gap-2"
            key={option}
            role="presentation"
          >
            <Checkbox
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${field}`}
              checked={isChecked}
              onChecked={(): AnyAction =>
                filterCandidatesByProperty(dispatch, field, option, isChecked)
              }
            />
            <span className="capitalize">
              {!dict?.length
                ? option
                : dict.find(
                    (item: DictionaryEntryModel): boolean => item.id === option,
                  )?.name || ""}
            </span>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default FilterOptions;
