import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { IAction } from "./types";
import ActionTypes from "./actionTypes";
import publicFolderReducer, {
  filtersReducer,
  menuReducer,
} from "../public-folder-domain/store/reducer";
import { dictionariesReducer } from "../dictionaries";
import publicProfilesReducer from "../public-profile-domain/store/reducer";
import { IS_PRODUCTION } from "../shared/constants";
import errorReducer from "./errorReducer";

const reducer = combineReducers({
  dictionaries: dictionariesReducer,
  filters: filtersReducer,
  menu: menuReducer,
  publicFolder: publicFolderReducer,
  publicProfiles: publicProfilesReducer,
  error: errorReducer,
});

const actionTypeEnumToString = (
  action: IAction<number | AnyAction>,
): string | AnyAction =>
  typeof action.type === "number" && ActionTypes[action.type]
    ? {
        type: ActionTypes[action.type],
        payload: action.payload,
        typeNumber: action.type,
      }
    : action;

const middlewares = [thunkMiddleware];

if (!IS_PRODUCTION) {
  const logger = createLogger({ actionTransformer: actionTypeEnumToString });
  middlewares.push(logger);
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

// eslint-disable-next-line no-undef
export type AppState = ReturnType<typeof reducer>;
export default store;
