import { ExperienceModel } from "../../models/types";

// eslint-disable-next-line import/prefer-default-export
export const prepareOtherExperience = (
  experience: ExperienceModel,
): { [key: string]: [] } =>
  Object.entries(experience)
    .filter(([section]) => section !== "projects")
    .reduce(
      (acc, [key, value]) =>
        value?.length
          ? {
              ...acc,
              [key]: value,
            }
          : acc,
      {},
    );
