import { ReactElement } from "react";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../../shared/data-cy";
import { ReactComponent as ExperienceIcon } from "../../../../images/profile-summary/experience.svg";
import { CardHeader } from "../../../expanded-card";
import { useTranslationWithNamespace } from "../../../../hooks";
import { OverallExperienceProps } from "../../types";
import Label from "../../../label";

const BASE_PATH = "profileSummary";

const Experience = ({
  overallExperience,
}: {
  overallExperience: OverallExperienceProps;
}): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      additionalClassName="h-full"
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_experience`}
      Icon={ExperienceIcon}
      iconColor="text-primary-50"
    >
      <Label
        additionalClassName="h-auto"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_experience`}
        text={t(`${BASE_PATH}.experience`)}
      >
        <span>
          {`${
            overallExperience?.years
              ? t(`${BASE_PATH}.years`, {
                  count: overallExperience.years,
                })
              : ""
          } ${
            overallExperience?.months
              ? t(`${BASE_PATH}.months`, {
                  count: overallExperience?.months,
                })
              : ""
          } `}
        </span>
      </Label>
    </CardHeader>
  );
};

export default Experience;
