/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Dict API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DictEntry
 */
export interface DictEntry {
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'icon_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictEntry
     */
    'desc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DictEntry
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DictType = {
    Domain: 'domain',
    Profession: 'profession',
    ProjectRole: 'project_role',
    Seniority: 'seniority',
    Technology: 'technology',
    Engagement: 'engagement'
} as const;

export type DictType = typeof DictType[keyof typeof DictType];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictEntries: async (dictType: DictType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictType' is not null or undefined
            assertParamExists('getDictEntries', 'dictType', dictType)
            const localVarPath = `/dict/{dictType}`
                .replace(`{${"dictType"}}`, encodeURIComponent(String(dictType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication FolderAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication TenantAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictEntries(dictType: DictType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DictEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictEntries(dictType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getDictEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Returns dict entry list of a given type
         * @param {DictType} dictType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictEntries(dictType: DictType, options?: any): AxiosPromise<Array<DictEntry>> {
            return localVarFp.getDictEntries(dictType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Returns dict entry list of a given type
     * @param {DictType} dictType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDictEntries(dictType: DictType, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDictEntries(dictType, options).then((request) => request(this.axios, this.basePath));
    }
}



