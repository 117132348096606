import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import ActionTypes from "../../store/actionTypes";
import { FilterPayload, IAction } from "../../store/types";
import PublicFolderService from "../services/publicFolder";

export const fetchPublicFolderStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_FOLDER_START);
export const fetchPublicFolderSuccess = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_FOLDER_SUCCESS, payload);
export const fetchPublicFolderError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_FOLDER_ERROR, payload);

export const fetchPublicFolder = async (
  dispatch: Dispatch,
  folderId: string,
  pin: string,
): Promise<AnyAction> => {
  dispatch(fetchPublicFolderStart());
  const response = await PublicFolderService.fetchPublicFolder(folderId, pin);
  if (isAPIError(response)) return dispatch(fetchPublicFolderError(response));
  return dispatch(fetchPublicFolderSuccess(response));
};

export const addFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.ADD_FILTER, payload);
export const removeFilter = (payload: FilterPayload): AnyAction =>
  actionCreator(ActionTypes.REMOVE_FILTER, payload);
export const resetFilters = (): AnyAction =>
  actionCreator(ActionTypes.RESET_FILTERS);

export const filterCandidatesByProperty = (
  dispatch: Dispatch,
  filterName: string,
  optionId: string,
  isChecked: boolean,
): AnyAction =>
  isChecked
    ? dispatch(removeFilter({ filterName, optionId }))
    : dispatch(addFilter({ filterName, optionId }));

export const resetCandidatesByPropertyFilters = (
  dispatch: Dispatch,
): AnyAction => dispatch(resetFilters());

export const hideMenu = (): AnyAction => actionCreator(ActionTypes.HIDE_MENU);
export const openMenu = (): AnyAction => actionCreator(ActionTypes.OPEN_MENU);
export const setCurrentTab = (payload: string): AnyAction =>
  actionCreator(ActionTypes.SET_CURRENT_TAB, payload);

export const setMenu = (
  dispatch: Dispatch,
  action: "hide" | "open" | "set",
  newSelectedTab?: string,
): AnyAction => {
  if (newSelectedTab && action === "set")
    return dispatch(setCurrentTab(newSelectedTab));
  return dispatch(action === "hide" ? hideMenu() : openMenu());
};
