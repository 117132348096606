import { ReactElement, useState, KeyboardEvent } from "react";
import { useDispatch } from "redux-react-hook";
import { useHistory } from "react-router-dom";
import { AnyAction } from "redux";
import {
  Avatar,
  Button,
  Chip,
  Icon,
  Label,
  UserCard,
  Tooltip,
  StatusChip,
} from "@ps/ui-components";
import { classJoin, getNameFromDictionary } from "@ps/utils";
import {
  AVATAR,
  OVERLAP,
  PROFESSION,
  USER_CARD,
  IN_PROGRESS,
  REVERT,
  APPROVED,
  REJECTED,
} from "../constants";
import { PROPOSAL_PUBLIC_FOLDER } from "../../shared/data-cy";
import { ReactComponent as AvatarIcon } from "../../images/avatar.svg";
import { ReactComponent as NeutralIcon } from "../../images/neutral.svg";
import { ReactComponent as LocationIcon } from "../../images/location.svg";
import { ReactComponent as ForwardIcon } from "../../images/forward.svg";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../hooks";
import { PersonProps, PersonMapState, PersonMapStateReturn } from "./types";
import PublicFolderService from "../services/publicFolder";
import { Acceptance } from "../api/openapi-client";
import { fetchPublicFolder } from "../store/actions";
import { focusVisibleStyles, Keys } from "../../shared";
import RejectModal from "./modals/rejectModal";

const BASE_PATH = "publicFolder";

const Person = ({ candidate, data }: PersonProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const history = useHistory();
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

  const mapState = (state: PersonMapState): PersonMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    professionDict: state.dictionaries?.profession || [],
  });

  const { seniorityDict, professionDict } = useMappedStateSelector(mapState);

  const candidateStatus: string =
    candidate.status[candidate.status?.length - 1]?.acceptance;

  const handleOnClickNeutral = async (): Promise<AnyAction> => {
    await PublicFolderService.updateCandidateStatus(
      data.id,
      candidate.userId,
      data.pin,
      {
        status: { acceptance: Acceptance.InProgress, feedback: "" },
        favorited: false,
      },
    );
    return fetchPublicFolder(dispatch, data.id, data.pin);
  };

  const matchColorToStatus = (): string => {
    switch (candidateStatus) {
      case APPROVED:
        return "bg-success-60 text-primary-100 border-success-60";
      case REJECTED:
        return "bg-error-30 text-primary-100 border-error-30";
      default:
        return "border-neutralSecondary-60";
    }
  };

  const handleOnClickSeeProfile = (): void =>
    history.push(`${data.id}/candidate/${candidate.userId}`);

  return (
    <>
      <RejectModal
        candidate={candidate}
        isModalOpen={rejectModalOpen}
        setIsModalOpen={setRejectModalOpen}
      />

      <UserCard
        dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${USER_CARD}`}
        borderColor={matchColorToStatus()}
        borderWidth={
          candidateStatus === APPROVED || candidateStatus === REJECTED
            ? "border-2"
            : ""
        }
        name={
          <span
            className={classJoin(
              "font-bold text-2xl text-primary-50 hover:underline text-center h-16 rounded-md",
              focusVisibleStyles,
            )}
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLSpanElement>): void => {
              if (event.key === Keys.ENTER) handleOnClickSeeProfile();
            }}
            onClick={handleOnClickSeeProfile}
          >{`${candidate.firstName} ${candidate.lastName}`}</span>
        }
        title={getNameFromDictionary(seniorityDict, candidate.seniority) || "-"}
        width="w-72"
        avatar={
          candidate?.avatar ? (
            <Avatar
              image={candidate?.avatar}
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${AVATAR}`}
              width={24}
              height={24}
              onClick={handleOnClickSeeProfile}
              cursorType="pointer"
            />
          ) : (
            <Icon
              icon={<AvatarIcon className="text-neutralSecondary-60" />}
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${AVATAR}`}
              size="24"
              onClick={handleOnClickSeeProfile}
              cursorType="pointer"
            />
          )
        }
        action={
          <div className="flex items-center text-neutralSecondary-41 gap-3 mb-4 h-6">
            {candidateStatus && candidateStatus !== IN_PROGRESS ? (
              <div className="flex items-center gap-2">
                <StatusChip
                  status={candidateStatus}
                  dataCy={PROPOSAL_PUBLIC_FOLDER}
                />
                <Tooltip
                  backgroundColor="primary-100"
                  textColor="neutralPrimary-50"
                  additionalClassName="border border-neutralPrimary-50"
                  placement="bottom"
                  content={<span>{t(`${BASE_PATH}.clearStatus`)}</span>}
                >
                  <Button
                    dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REVERT}`}
                    onClick={handleOnClickNeutral}
                    variant="primaryNegative"
                    paddingX="px-2"
                    height="h-8"
                  >
                    <NeutralIcon />
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-5 items-center">
          <div className="h-6 mt-3">
            {candidate?.professions?.length ? (
              <Chip
                dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${PROFESSION}`}
                label={getNameFromDictionary(
                  professionDict,
                  candidate.professions[0],
                )}
                additionalClassName="bg-neutralPrimary-85 w-min whitespace-nowrap"
              />
            ) : (
              <></>
            )}
          </div>
          <div className="flex items-start gap-1 h-10">
            {candidate.country ? (
              <>
                <LocationIcon className="text-primary-50 mt-2" />
                <span className="text-neutralPrimary-20">
                  {candidate.country}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
          {candidate.timeZone && (
            <Label
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${OVERLAP}`}
              text={t(`${BASE_PATH}.${OVERLAP}`)}
            >
              {candidate.timeZone}
            </Label>
          )}
          <Button
            dataCy={`${PROPOSAL_PUBLIC_FOLDER}_more`}
            additionalClass="p-3"
            onClick={handleOnClickSeeProfile}
          >
            <span className="flex items-center gap-2">
              {t(`${BASE_PATH}.more`)}
              <ForwardIcon />
            </span>
          </Button>
        </div>
      </UserCard>
    </>
  );
};

export default Person;
