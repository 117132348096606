import { IAPITechStack } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const prepareSummaryTechStack = (
  techStack: IAPITechStack[],
): { technologyId?: string; isHighlighted: boolean }[] =>
  techStack.map((item) => ({
    technologyId: item?.technology,
    isHighlighted: item?.isHightlighted || false,
  }));
