import { ReactElement } from "react";
import { Modal, Button } from "@ps/ui-components";
import { PROPOSAL_PUBLIC_FOLDER } from "../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../hooks";
import { ReactComponent as InfoGraphic } from "../../../images/infoGraphic.svg";
import { REJECTED, APPROVED } from "../../constants";
import { InfoModalProps } from "./types";

const BASE_PATH = "publicFolder";

const InfoModal = ({
  isModalOpen,
  setIsModalOpen,
}: InfoModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <Modal
      isOpen={isModalOpen}
      withOutsideClick
      withYScroll={false}
      height="h-max"
      width="w-4/5 lg:w-max"
      dataCy={`${PROPOSAL_PUBLIC_FOLDER}-info-modal`}
      onClose={(): void => setIsModalOpen(false)}
    >
      <div className="flex flex-col lg:flex-row items-center gap-12 w-full h-full">
        <InfoGraphic />
        <div className="flex flex-col gap-8 words">
          <span className="text-xl text-center lg:text-left font-semibold">
            {t(`${BASE_PATH}.usersProfile`)}
          </span>
          <span>{t(`${BASE_PATH}.info1`)}</span>
          <span>
            {t(`${BASE_PATH}.the`)}
            <span className="font-bold">{` "${t(
              `${BASE_PATH}.${REJECTED}`,
            )}" `}</span>
            {t(`${BASE_PATH}.info2`)}
          </span>
          <span>
            {t(`${BASE_PATH}.the`)}
            <span className="font-bold">{` "${t(
              `${BASE_PATH}.${APPROVED}`,
            )}" `}</span>
            {t(`${BASE_PATH}.info3`)}
          </span>
          <span>{t(`${BASE_PATH}.info4`)}</span>
          <Button
            dataCy={`${PROPOSAL_PUBLIC_FOLDER}-gotit`}
            onClick={(): void => setIsModalOpen(false)}
            additionalClass="self-center lg:self-start"
          >
            {t(`${BASE_PATH}.gotIt`)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
