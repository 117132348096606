import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import ThemeToggle from "../theme-toggle";
import { FontSizeSwitch } from "../font-size-provider";
import { PagePinProps } from "./types";
import { PAGE_PIN_PREFIX } from "../../shared/data-cy";
import { ReactComponent as Logo } from "../../images/teamyai-logo.svg";
import { ReactComponent as LogoHighContrast } from "../../images/teamyai-logo-highContrast.svg";
import { ReactComponent as EyeIcon } from "../../images/contrast-eye.svg";
import { ReactComponent as PinPageIcon } from "../../images/pin-page-icon.svg";
import { ReactComponent as PinPageIconSmall } from "../../images/pin-page-icon-small.svg";
import { useTranslationWithNamespace } from "../../hooks";
import PinInput from "../pin-input";
import Background from "../question-screen/background";
import { focusVisibleStyles, Keys } from "../../shared";

const BASE_PATH = "pages.pin";
const pinPageIconStyles =
  "absolute bottom-10 left-10 xl:bottom-32 xl:left-24 hidden lg:block";

const PagePin = ({
  dataCy,
  onPinChange,
  onPinEntryComplete,
  hasError,
}: PagePinProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast, theme, toggle, set } = useThemeMode();

  return (
    <div
      className="bg-neutralPrimary-100 w-screen h-full overflow-auto flex justify-start flex-col md:flex-row"
      data-cy={`${PAGE_PIN_PREFIX}_${dataCy}`}
    >
      <div
        className={classJoin(
          "relative w-full lg:w-1/2 h-auto md:h-full flex justify-start md:rounded-tr-5xl rounded-br-5xl",
          isHighContrast
            ? ""
            : "bg-gradient-to-r from-primary-20 to-primary-50",
        )}
      >
        {isHighContrast ? <></> : <Background />}
        <div
          className={`
            w-full mx-8 mt-4 mb-12 lg:mx-40 lg:my-32 xl:mx-48 xl:my-40 rounded-3xl
            lg:rounded-5xl px-4 lg:px-8 pt-8 lg:p-16 flex flex-col gap-4 lg:gap-32
          `}
          style={{
            background:
              "linear-gradient(180deg, rgba(141, 149, 213, 0.21) 0.55%, rgba(133, 145, 237, 0.17) 100%)",
            backdropFilter: "blur(42px)",
          }}
        >
          <div className="flex flex-col gap-3 w-44">
            {isHighContrast ? <LogoHighContrast /> : <Logo />}
            <div className="border-b border-white" />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-white text-xl sm:text-5xl">
              {t(`${BASE_PATH}.welcome`)}
            </span>
            <span className="text-white text-lg sm:text-xl font-light mt-6">
              {t(`${BASE_PATH}.description`)}
            </span>
          </div>
          <div className="block lg:hidden h-auto -mb-6">
            <PinPageIconSmall className="h-auto" />
          </div>
        </div>
        <PinPageIcon className={pinPageIconStyles} />
      </div>
      <div className="flex flex-col w-full lg:w-1/2 justify-center items-center py-16 lg:p-0 relative">
        <div className="flex items-center gap-3 absolute z-10 top-6 right-10">
          <FontSizeSwitch textColor="text-neutralPrimary-20" />
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (event.key === Keys.ENTER)
                isHighContrast ? set("light") : set("highContrast");
            }}
            className={classJoin("w-7 h-7 rounded-md", focusVisibleStyles)}
          >
            <EyeIcon
              className="text-neutralPrimary-20 cursor-pointer"
              onClick={() =>
                isHighContrast ? set("light") : set("highContrast")
              }
            />
          </div>
          {isHighContrast ? (
            <div style={{ width: "40px" }} />
          ) : (
            <ThemeToggle
              dataCy={PAGE_PIN_PREFIX}
              checked={theme === "dark"}
              onChange={toggle}
            />
          )}
        </div>
        <div className="flex flex-col gap-3 items-center lg:items-start px-2">
          <span className="text-neutralPrimary-20 text-2xl md:text-4xl lg:text-7xl font-semibold">
            {t(`${BASE_PATH}.enterYour`)}
          </span>
          <span className="text-neutralPrimary-20 text-center text-lg">
            {t(`${BASE_PATH}.text`)}
          </span>
          <div className={classJoin(hasError ? "mt-4" : "mt-24")}>
            <PinInput
              dataCy={dataCy}
              onlyNumbers
              onPinChange={onPinChange}
              onPinEntryComplete={onPinEntryComplete}
              hasError={hasError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePin;
