import {
  colorFormatters,
  ISOToDayJs,
  locationSelector,
  prepareCareerPauseModel,
  skillsColors,
  techStack,
} from "@ps/utils";
import { APIPublicProfileNeed, PublicProfileNeedModel } from "../types";
import { prepareTimeRanges } from "../helpers";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";
import { COURSE, DEGREE } from "../../../shared/constants";
import { PROFILE_SUMMARY_HIDDEN_FIELDS } from "../../constants";

const { prepareSummaryTechStack } = techStack;

const PublicProfileNeedToModel = (
  data: APIPublicProfileNeed,
): PublicProfileNeedModel => ({
  avatar: data?.avatar,
  firstName: data.firstName,
  lastName: data.lastName || "",
  bio: data.bio,
  residence: {
    label: locationSelector.getResidenceData(
      CURRENT_LANGUAGE_CODE,
      data?.countryOfResidence || "",
    ),
    value: data?.countryOfResidence || "",
  },
  seniority: data.seniority,
  projectRoles: data.projectRoles || [],
  professions: data.professions,
  careerSpan: data.careerSpan
    ? {
        startDate: ISOToDayJs(data.careerSpan.startDate),
        gapMonths: data.careerSpan
          ? prepareCareerPauseModel(data.careerSpan.gapMonths)
          : {},
      }
    : undefined,
  timeRanges: prepareTimeRanges(data.timeRanges),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  languages: data.languages,
  skills: {
    best: data.skills.best.map((skill) => ({
      technologyId: skill.technology,
      ...skillsColors.prepareBestSkillsWithColors(
        skill,
        data?.skills?.byProfession,
        data?.professions?.main,
      ),
    })),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    byProfession: data?.skills?.byProfession
      .map((profession) => ({
        professionId: profession.profession,
        scale: profession.scale,
        skills: profession.ratings
          .filter((item) => item.score > 0)
          .map((skill) => ({
            technologyId: skill.technology,
            score: skill.score,
            color: colorFormatters.prepareRGBObjectToHEX(
              profession?.scale?.find(
                (scaleItem) => scaleItem.score === skill.score,
              )?.color,
            ),
          })),
      }))
      .filter((item) => item.skills.length),
  },
  domains: Object.entries(data?.domains?.ratings || {})
    ?.filter(([_, el]) => !!el.knowledge) // eslint-disable-line @typescript-eslint/no-unused-vars
    .map(([key, value]) => ({
      technologyId: key,
      score: value?.knowledge,
      color: colorFormatters.prepareRGBObjectToHEX(
        data?.domains?.scale?.find(
          (scaleItem) => scaleItem.score === value?.knowledge,
        )?.color,
      ),
    }))
    ?.sort((a, b) => (a?.score && b?.score && a.score > b.score ? -1 : 1)),
  experience: {
    projects: data.experience?.projects?.map((project) => ({
      domain: project.domain,
      name: project.name,
      startDate: ISOToDayJs(project.startDate),
      endDate: ISOToDayJs(project.endDate) || undefined,
      description: project.desc,
      responsibility: project.responsibility,
      projectRoles: project.projectRoles,
      techStack: prepareSummaryTechStack(project.techStack),
    })),
    courses: data.experience?.educations
      .filter((item) => item.education === COURSE)
      .map((course) => ({
        education: course.education,
        startDate: ISOToDayJs(course.startDate),
        endDate: ISOToDayJs(course.endDate) || undefined,
        description: course.desc,
        courseName: course.courseName,
        trainer: PROFILE_SUMMARY_HIDDEN_FIELDS.includes(course.trainer)
          ? ""
          : course.trainer,
      })),
    degrees: data.experience?.educations
      .filter((item) => item.education === DEGREE)
      .map((degree) => ({
        education: degree.education,
        startDate: ISOToDayJs(degree.startDate),
        endDate: ISOToDayJs(degree.endDate) || undefined,
        description: degree.desc,
        fieldOfStudy: degree.fieldOfStudy,
        degree: PROFILE_SUMMARY_HIDDEN_FIELDS.includes(degree.degree)
          ? ""
          : degree.degree,
        schoolName: degree.schoolName,
      })),
    articles: data.experience?.articles.map((article) => ({
      title: article.title,
      publicationDate: ISOToDayJs(article.publicationDate),
      url: article.url,
      techStack: prepareSummaryTechStack(article.techStack),
    })),
    talks: data.experience?.talks.map((talk) => ({
      eventName: PROFILE_SUMMARY_HIDDEN_FIELDS.includes(talk.eventName)
        ? ""
        : talk.eventName,
      title: talk.title,
      url: talk.url,
      videoUrl: talk.videoUrl,
      date: ISOToDayJs(talk.date),
      techStack: prepareSummaryTechStack(talk.techStack),
    })),
    contributions: data.experience?.contributions.map((contribution) => ({
      name: contribution.name,
      url: contribution.url,
      startDate: ISOToDayJs(contribution.startDate),
      endDate: ISOToDayJs(contribution.endDate) || undefined,
      techStack: prepareSummaryTechStack(contribution.techStack),
    })),
    tutors: data.experience?.tutors.map((tutor) => ({
      title: tutor.title,
      description: tutor.desc,
      date: ISOToDayJs(tutor.date) || undefined,
      techStack: prepareSummaryTechStack(tutor.techStack),
    })),
    awards: data.experience?.awards.map((award) => ({
      awardName: award.awardName,
      rewarder: award.rewarder,
      date: ISOToDayJs(award.date),
      description: award.description,
      techStack: prepareSummaryTechStack(award.techStack),
    })),
    certificates: data.experience?.certificates.map((certificate) => ({
      title: certificate.title,
      url: certificate.url,
      issuedDate: ISOToDayJs(certificate.issuedDate),
      expirationDate: ISOToDayJs(certificate.expirationDate) || undefined,
      techStack: prepareSummaryTechStack(certificate.techStack),
    })),
  },
});

// eslint-disable-next-line import/prefer-default-export
export { PublicProfileNeedToModel };
