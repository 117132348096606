import { AnyAction, Dispatch } from "redux";
import { actionCreator } from "@ps/utils";
import { isAPIError } from "@ps/api-utils";
import { IAction } from "../../store/types";
import * as ActionTypes from "./actionTypes";
import PublicProfileService from "../services/publicProfile";

export const fetchPublicProfileStart = (): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_PROFILE_START);
export const fetchPublicProfileSuccess = (
  payload: IAction<string>,
): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_PROFILE_SUCCESS, payload);
export const fetchPublicProfileError = (payload: IAction<string>): AnyAction =>
  actionCreator(ActionTypes.FETCH_PUBLIC_PROFILE_ERROR, payload);

export const fetchPublicProfile = async (
  dispatch: Dispatch,
  profileId: string,
): Promise<AnyAction> => {
  dispatch(fetchPublicProfileStart());
  const response = await PublicProfileService.fetchPublicProfile(profileId);
  if (isAPIError(response)) return dispatch(fetchPublicProfileError(response));
  return dispatch(fetchPublicProfileSuccess(response));
};
