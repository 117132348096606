enum ActionTypes {
  FETCH_PUBLIC_FOLDER_START,
  FETCH_PUBLIC_FOLDER_SUCCESS,
  FETCH_PUBLIC_FOLDER_ERROR,
  FETCH_PUBLIC_PROFILE_START,
  FETCH_PUBLIC_PROFILE_SUCCESS,
  FETCH_PUBLIC_PROFILE_ERROR,
  ADD_FILTER,
  REMOVE_FILTER,
  RESET_FILTERS,
  HIDE_MENU,
  OPEN_MENU,
  SET_CURRENT_TAB,
  CLEAR_ERROR,
}

export default ActionTypes;
