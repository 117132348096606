/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Public Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CareerSpan
 */
export interface CareerSpan {
    /**
     * 
     * @type {string}
     * @memberof CareerSpan
     */
    'startDate': string;
    /**
     * The number of months considered to be \'gap\' months in the careerSpan of a Specialist
     * @type {number}
     * @memberof CareerSpan
     */
    'gapMonths': number;
}
/**
 * Each RGB value needs to be between 0 and 255
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'red': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'green': number;
    /**
     * RGB color channels
     * @type {number}
     * @memberof Color
     */
    'blue': number;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course extends Education {
    /**
     * A name of the person/company that hosted the course
     * @type {string}
     * @memberof Course
     */
    'trainer': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'courseName': string;
}
/**
 * 
 * @export
 * @interface Degree
 */
export interface Degree extends Education {
    /**
     * The name of the university or college
     * @type {string}
     * @memberof Degree
     */
    'schoolName': string;
    /**
     * Eg. \'IT\' etc.
     * @type {string}
     * @memberof Degree
     */
    'fieldOfStudy': string;
    /**
     * Eg. \'MBA\'
     * @type {string}
     * @memberof Degree
     */
    'degree': string;
}
/**
 * Score for knowledge and enjoyment for a given domain, values should be compatible with the scale given by the DomainMatrixTemplate
 * @export
 * @interface DomainRating
 */
export interface DomainRating {
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'knowledge'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainRating
     */
    'enjoyment'?: number;
}
/**
 * 
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * Education type discriminator either \'course\' or \'degree\'
     * @type {string}
     * @memberof Education
     */
    'education': string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'desc'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetProfileForNeed
 */
export interface GetProfileForNeed {
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'countryOfResidence'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileForNeed
     */
    'seniority'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProfileForNeed
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {Professions}
     * @memberof GetProfileForNeed
     */
    'professions'?: Professions;
    /**
     * 
     * @type {CareerSpan}
     * @memberof GetProfileForNeed
     */
    'careerSpan'?: CareerSpan;
    /**
     * 
     * @type {TimeRanges}
     * @memberof GetProfileForNeed
     */
    'timeRanges': TimeRanges;
    /**
     * 
     * @type {Array<LanguageProficiency>}
     * @memberof GetProfileForNeed
     */
    'languages': Array<LanguageProficiency>;
    /**
     * 
     * @type {Skills}
     * @memberof GetProfileForNeed
     */
    'skills': Skills;
    /**
     * 
     * @type {ProfileDomainMatrixFulfillment}
     * @memberof GetProfileForNeed
     */
    'domains'?: ProfileDomainMatrixFulfillment;
    /**
     * 
     * @type {HighlightedExperience}
     * @memberof GetProfileForNeed
     */
    'experience': HighlightedExperience;
}
/**
 * 
 * @export
 * @interface GetRating
 */
export interface GetRating {
    /**
     * Score tied to a given rating
     * @type {number}
     * @memberof GetRating
     */
    'score': number;
    /**
     * A non blank description that gives us context about the entry
     * @type {string}
     * @memberof GetRating
     */
    'desc': string;
    /**
     * 
     * @type {Color}
     * @memberof GetRating
     */
    'color'?: Color;
    /**
     * URL of an image that\'ll serve as an icon for this entry
     * @type {string}
     * @memberof GetRating
     */
    'iconUrl'?: string;
}
/**
 * 
 * @export
 * @interface HighlightedArticle
 */
export interface HighlightedArticle {
    /**
     * 
     * @type {string}
     * @memberof HighlightedArticle
     */
    'title': string;
    /**
     * Date, formatted as YYYY-MM-DD
     * @type {string}
     * @memberof HighlightedArticle
     */
    'publicationDate': string;
    /**
     * An URL to the article
     * @type {string}
     * @memberof HighlightedArticle
     */
    'url'?: string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedArticle
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @interface HighlightedAward
 */
export interface HighlightedAward {
    /**
     * Award name eg. \'Best Cooking Blog of 2019\'
     * @type {string}
     * @memberof HighlightedAward
     */
    'awardName': string;
    /**
     * The entity that supplies the award eg. \'medium\'
     * @type {string}
     * @memberof HighlightedAward
     */
    'rewarder': string;
    /**
     * A date on which the award was rewarded
     * @type {string}
     * @memberof HighlightedAward
     */
    'date': string;
    /**
     * A description of the award
     * @type {string}
     * @memberof HighlightedAward
     */
    'description'?: string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedAward
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @interface HighlightedCertificate
 */
export interface HighlightedCertificate {
    /**
     * The title of the certificate eg. \'Certified Lightbend Reactive Developer\'
     * @type {string}
     * @memberof HighlightedCertificate
     */
    'title': string;
    /**
     * The URL which accesses the certificate
     * @type {string}
     * @memberof HighlightedCertificate
     */
    'url'?: string;
    /**
     * The date on which the certificate was issued
     * @type {string}
     * @memberof HighlightedCertificate
     */
    'issuedDate': string;
    /**
     * The date on which the certificate expires
     * @type {string}
     * @memberof HighlightedCertificate
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedCertificate
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @interface HighlightedContribution
 */
export interface HighlightedContribution {
    /**
     * Eg. a Github project name
     * @type {string}
     * @memberof HighlightedContribution
     */
    'name': string;
    /**
     * An URL to the PR/contribution in some other form
     * @type {string}
     * @memberof HighlightedContribution
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedContribution
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedContribution
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedContribution
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @interface HighlightedExperience
 */
export interface HighlightedExperience {
    /**
     * 
     * @type {Array<HighlightedProject>}
     * @memberof HighlightedExperience
     */
    'projects': Array<HighlightedProject>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof HighlightedExperience
     */
    'educations': Array<Education>;
    /**
     * 
     * @type {Array<HighlightedArticle>}
     * @memberof HighlightedExperience
     */
    'articles': Array<HighlightedArticle>;
    /**
     * 
     * @type {Array<HighlightedTalk>}
     * @memberof HighlightedExperience
     */
    'talks': Array<HighlightedTalk>;
    /**
     * 
     * @type {Array<HighlightedContribution>}
     * @memberof HighlightedExperience
     */
    'contributions': Array<HighlightedContribution>;
    /**
     * 
     * @type {Array<HighlightedTutor>}
     * @memberof HighlightedExperience
     */
    'tutors': Array<HighlightedTutor>;
    /**
     * 
     * @type {Array<HighlightedAward>}
     * @memberof HighlightedExperience
     */
    'awards': Array<HighlightedAward>;
    /**
     * 
     * @type {Array<HighlightedCertificate>}
     * @memberof HighlightedExperience
     */
    'certificates': Array<HighlightedCertificate>;
}
/**
 * 
 * @export
 * @interface HighlightedProject
 */
export interface HighlightedProject {
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'desc': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedProject
     */
    'responsibility': string;
    /**
     * List of project roles
     * @type {Array<string>}
     * @memberof HighlightedProject
     */
    'projectRoles': Array<string>;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedProject
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @interface HighlightedTalk
 */
export interface HighlightedTalk {
    /**
     * 
     * @type {string}
     * @memberof HighlightedTalk
     */
    'eventName': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedTalk
     */
    'title': string;
    /**
     * An URL to the talk
     * @type {string}
     * @memberof HighlightedTalk
     */
    'url'?: string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedTalk
     */
    'techStack': Array<HighlightedTechnology>;
    /**
     * The date on which the talk has taken place
     * @type {string}
     * @memberof HighlightedTalk
     */
    'date'?: string;
    /**
     * An URL to a video of the talk
     * @type {string}
     * @memberof HighlightedTalk
     */
    'videoUrl'?: string;
}
/**
 * 
 * @export
 * @interface HighlightedTechnology
 */
export interface HighlightedTechnology {
    /**
     * 
     * @type {string}
     * @memberof HighlightedTechnology
     */
    'technology': string;
    /**
     * 
     * @type {boolean}
     * @memberof HighlightedTechnology
     */
    'isHightlighted': boolean;
}
/**
 * 
 * @export
 * @interface HighlightedTutor
 */
export interface HighlightedTutor {
    /**
     * Name of the workshop you tutored
     * @type {string}
     * @memberof HighlightedTutor
     */
    'title': string;
    /**
     * A date on which the workshop occurred
     * @type {string}
     * @memberof HighlightedTutor
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof HighlightedTutor
     */
    'desc': string;
    /**
     * 
     * @type {Array<HighlightedTechnology>}
     * @memberof HighlightedTutor
     */
    'techStack': Array<HighlightedTechnology>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageLevel = {
    Elementary: 'Elementary',
    Limited: 'Limited',
    Professional: 'Professional',
    FullyProfessional: 'FullyProfessional',
    Native: 'Native'
} as const;

export type LanguageLevel = typeof LanguageLevel[keyof typeof LanguageLevel];


/**
 * 
 * @export
 * @interface LanguageProficiency
 */
export interface LanguageProficiency {
    /**
     * 
     * @type {string}
     * @memberof LanguageProficiency
     */
    'language': string;
    /**
     * 
     * @type {LanguageLevel}
     * @memberof LanguageProficiency
     */
    'level': LanguageLevel;
}


/**
 * 
 * @export
 * @interface ProfessionSkills
 */
export interface ProfessionSkills {
    /**
     * 
     * @type {string}
     * @memberof ProfessionSkills
     */
    'profession': string;
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof ProfessionSkills
     */
    'scale': Array<GetRating>;
    /**
     * List of skills in a given Profession sorted by score.
     * @type {Array<TechnologySkill>}
     * @memberof ProfessionSkills
     */
    'ratings': Array<TechnologySkill>;
}
/**
 * 
 * @export
 * @interface Professions
 */
export interface Professions {
    /**
     * 
     * @type {string}
     * @memberof Professions
     */
    'main': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Professions
     */
    'secondary': Array<string>;
}
/**
 * 
 * @export
 * @interface ProfileDomainMatrixFulfillment
 */
export interface ProfileDomainMatrixFulfillment {
    /**
     * A DomainId-to-DomainRating dictionary
     * @type {{ [key: string]: DomainRating; }}
     * @memberof ProfileDomainMatrixFulfillment
     */
    'ratings': { [key: string]: DomainRating; };
    /**
     * 
     * @type {Array<GetRating>}
     * @memberof ProfileDomainMatrixFulfillment
     */
    'scale': Array<GetRating>;
}
/**
 * 
 * @export
 * @interface Skills
 */
export interface Skills {
    /**
     * List of most relevant/best skills sorted by score.
     * @type {Array<TechnologySkill>}
     * @memberof Skills
     */
    'best': Array<TechnologySkill>;
    /**
     * List of skills grouped by profession.
     * @type {Array<ProfessionSkills>}
     * @memberof Skills
     */
    'byProfession': Array<ProfessionSkills>;
}
/**
 * 
 * @export
 * @interface TechnologySkill
 */
export interface TechnologySkill {
    /**
     * 
     * @type {string}
     * @memberof TechnologySkill
     */
    'technology': string;
    /**
     * 
     * @type {number}
     * @memberof TechnologySkill
     */
    'score': number;
}
/**
 * A time range, start and end formatted as ISO 8601 time without timezone (eg. 10:15:30)
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeRange
     */
    'end': string;
}
/**
 * All time-related here information is converted to UTC
 * @export
 * @interface TimeRanges
 */
export interface TimeRanges {
    /**
     * 
     * @type {TimeRange}
     * @memberof TimeRanges
     */
    'need'?: TimeRange;
    /**
     * 
     * @type {TimeRange}
     * @memberof TimeRanges
     */
    'possible'?: TimeRange;
    /**
     * 
     * @type {TimeRange}
     * @memberof TimeRanges
     */
    'comfortable'?: TimeRange;
    /**
     * A hint to redefine the timezone information to the one specified here
     * @type {string}
     * @memberof TimeRanges
     */
    'needTimezone'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches a profile of a Specialist catered to a specific need.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileForNeed: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProfileForNeed', 'userId', userId)
            const localVarPath = `/profile/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication FolderAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication TenantAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches a profile of a Specialist catered to a specific need.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileForNeed(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileForNeed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileForNeed(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getProfileForNeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Fetches a profile of a Specialist catered to a specific need.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileForNeed(userId: string, options?: any): AxiosPromise<GetProfileForNeed> {
            return localVarFp.getProfileForNeed(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Fetches a profile of a Specialist catered to a specific need.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfileForNeed(userId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProfileForNeed(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



