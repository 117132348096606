import { ReactElement } from "react";
import { classJoin } from "@ps/utils";
import { PageBackground } from "@ps/ui-components";
import { ReactComponent as Icons404 } from "../../images/404.svg";
import { useTranslationWithNamespace } from "../../hooks";
import styles from "./styles.module.css";
import { PAGE_404_PUBLIC_FOLDER } from "../../shared/data-cy";

const Page404 = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const imageStyles = "bottom-80 right-20 md:w-auto w-inherit";

  return (
    <div
      data-cy={PAGE_404_PUBLIC_FOLDER}
      className="h-screen w-screen relative"
    >
      <PageBackground />
      <div
        className={classJoin(
          "absolute flex items-center justify-center h-screen w-screen gap-2 flex-col md:flex-row p-20",
          styles.content,
        )}
      >
        <div className="flex flex-col top-1/3 left-96 gap-y-5 w-96">
          <span className="text-9xl text-white font-black">
            {t("notFoundPage.code")}
          </span>
          <span className="text-5xl text-white font-semibold">
            {t("notFoundPage.header")}
          </span>
          <span className="text-xl text-white pt-10">
            {t("notFoundPage.content")}
          </span>
        </div>
        <Icons404 className={imageStyles} />
      </div>
    </div>
  );
};

export default Page404;
