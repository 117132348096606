// eslint-disable-next-line import/prefer-default-export
export const languageLevelsDict = [
  "Elementary",
  "Limited",
  "Professional",
  "FullyProfessional",
  "Native",
];

export const CANDIDATE_SUMMARY = "candidateSummary";

export const PROFILE_SUMMARY_HIDDEN_FIELDS: string[] = [
  "Unknown Event Name",
  "Non-Specified",
  "non specified",
];
