import {
  DictionaryAPIModel,
  DictionaryEntryAPIModel,
  DictionaryEntryModel,
  DictionaryModel,
} from "../types";

const fromAPIToEntryModel = (
  dictionary: DictionaryAPIModel,
): DictionaryModel | [] =>
  dictionary?.length
    ? dictionary.map(
        (item: DictionaryEntryAPIModel): DictionaryEntryModel => ({
          id: item.id,
          name: item.name,
          iconUrl: item.icon_url,
          desc: item.desc,
          deleted: item.deleted,
        }),
      )
    : [];

export default fromAPIToEntryModel;
