import {
  PublicFolderUpdateCandidateStatusAPIModel,
  PublicFolderUpdateCandidateStatusModel,
} from "../types";

// eslint-disable-next-line import/prefer-default-export
export const toAPIModel = (
  data: PublicFolderUpdateCandidateStatusModel,
): PublicFolderUpdateCandidateStatusAPIModel => ({
  status: {
    acceptance: data.status.acceptance,
    feedback: data.status?.feedback,
  },
  favorited: data.favorited,
});
