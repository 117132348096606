import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";
import { DictionaryModel } from "../models/types";

export const dictionariesReducer = (
  state = {},
  action: AnyAction,
): { [key: string]: DictionaryModel } => {
  switch (action.type) {
    case ActionTypes.FETCH_DICTIONARY_START:
      return state;
    case ActionTypes.FETCH_DICTIONARY_SUCCESS:
      return { ...state, [action.payload.id]: action.payload.dictionaries };
    case ActionTypes.FETCH_DICTIONARY_ERROR:
      return {};
    default:
      return state;
  }
};

export const isFetchingDictionary = (
  state = false,
  action: AnyAction,
): boolean => {
  switch (action.type) {
    case ActionTypes.FETCH_DICTIONARY_START:
      return true;
    case ActionTypes.FETCH_DICTIONARY_SUCCESS:
    case ActionTypes.FETCH_DICTIONARY_ERROR:
      return false;
    default:
      return state;
  }
};
