import { ReactElement, useEffect, useState } from "react";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  CircleButton,
  Icon,
  LoadingMainPage,
  ScrollableWrapper,
  SectionTabs,
} from "@ps/ui-components";
import {
  classJoin,
  removeDiacriticsFromString,
  useFullTextSearch,
} from "@ps/utils";
import {
  ALL_PROFILES,
  APPROVED,
  DICTIONARIES,
  PUBLIC_FOLDER_PATH,
  REJECTED,
} from "../constants";
import { ReactComponent as AllProfilesIcon } from "../../images/allProfiles.svg";
import { ReactComponent as ApprovedIcon } from "../../images/approved.svg";
import { ReactComponent as RejectedIcon } from "../../images/rejected.svg";
import { ReactComponent as MenuIcon } from "../../images/menu.svg";
import Person from "./person";
import {
  ExtendedPublicFolderCandidateModel,
  PublicFolderCandidateModel,
} from "../models/types";
import { setMenu } from "../store/actions";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import { fetchDictionary } from "../../dictionaries";
import NoUsersFound from "./noUsersFound";
import { PublicFolderMapState, PublicFolderMapStateReturn } from "./types";

import SearchFilters from "./searchFilters.tsx/searchFilterRow";
import {
  filterCandidates,
  filterCandidatesByProperty,
  prepareExtendedCandidatesList,
} from "./utils/utils";

const PublicFolder = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>("");
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);

  const [baseCandidatesList, setBaseCandidatesList] = useState<
    ExtendedPublicFolderCandidateModel[]
  >([]);

  const mapState = (
    state: PublicFolderMapState,
  ): PublicFolderMapStateReturn => ({
    publicFolder: state.publicFolder,
    allDictionaries: state.allDictionaries,
    filters: state.filters,
    menu: state.menu,
    areFiltersChecked: Object.entries(state.filters).some(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => value?.length,
    ),
  });

  const { allDictionaries, areFiltersChecked, filters, menu, publicFolder } =
    useMappedStateSelector(mapState);

  useEffect(() => {
    const neededDictionaries: string[] = [
      DICTIONARIES.TECHNOLOGY,
      DICTIONARIES.SENIORITY,
      DICTIONARIES.PROFESSION,
      DICTIONARIES.PROJECT_ROLE,
      DICTIONARIES.DOMAIN,
    ].reduce(
      (acc: string[], dictionaryName: string) =>
        allDictionaries?.[dictionaryName] ? acc : [...acc, dictionaryName],
      [],
    );
    neededDictionaries?.map(
      (dictID: string): Promise<AnyAction> => fetchDictionary(dictID, dispatch),
    );
  }, []);

  useEffect(() => {
    if (publicFolder?.candidates?.length) {
      setBaseCandidatesList(
        prepareExtendedCandidatesList(publicFolder.candidates),
      );
    }
  }, [publicFolder.candidates]);

  const filteredCandidatesByProperty = areFiltersChecked
    ? filterCandidatesByProperty(baseCandidatesList, filters)
    : baseCandidatesList;

  const searchDictionary = filterCandidates(
    menu.selectedTab,
    filteredCandidatesByProperty,
  );

  const { search } = useFullTextSearch(
    filteredCandidatesByProperty,
    undefined,
    {
      keys: ["noForeignCharsFullName"],
    },
  );

  const dataToDisplay = searchValue
    ? filterCandidates(
        menu.selectedTab,
        search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ).reverse() as ExtendedPublicFolderCandidateModel[],
      )
    : searchDictionary;

  const countOnTabAmount = (section: string): number => {
    if (menu.selectedTab === section) return dataToDisplay.length;
    if (searchValue)
      return filterCandidates(
        section,
        search(
          removeDiacriticsFromString(searchValue.toLowerCase()),
        ) as ExtendedPublicFolderCandidateModel[],
      ).length;
    return filterCandidates(section, filteredCandidatesByProperty).length;
  };

  const renderCircleButtonChild = (section: string): ReactElement => (
    <CircleButton
      isActive={menu.selectedTab === section}
      nonActiveStyles="bg-secondary-95 border-neutralPrimary-50 text-neutralPrimary-50"
      activeNumber={countOnTabAmount(section)}
      activeStyles="bg-success-99 border-success-50 text-success-50"
      dataCy={ALL_PROFILES}
    />
  );

  const handleOnClickNewTab = (newTab: string): void => {
    setMenu(dispatch, "set", newTab);
    if (isMobileMenuOpened) setIsMobileMenuOpened(false);
  };

  const tabs = [
    {
      icon: <Icon icon={<AllProfilesIcon />} size="11" dataCy={ALL_PROFILES} />,
      title: t(`${PUBLIC_FOLDER_PATH}.${ALL_PROFILES}`),
      tabKey: ALL_PROFILES,
      children: renderCircleButtonChild(ALL_PROFILES),
      onClick: handleOnClickNewTab,
    },
    {
      icon: <Icon icon={<ApprovedIcon />} size="11" dataCy={APPROVED} />,
      title: t(`${PUBLIC_FOLDER_PATH}.${APPROVED}`),
      tabKey: APPROVED,
      children: renderCircleButtonChild(APPROVED),
      onClick: handleOnClickNewTab,
    },
    {
      icon: <Icon icon={<RejectedIcon />} size="11" dataCy={REJECTED} />,
      title: t(`${PUBLIC_FOLDER_PATH}.${REJECTED}`),
      tabKey: REJECTED,
      children: renderCircleButtonChild(REJECTED),
      onClick: handleOnClickNewTab,
    },
  ];

  return (
    <section className="pb-5 h-full">
      {publicFolder?.id ? (
        <div className="flex flex-col h-full">
          <SearchFilters
            baseCandidatesList={baseCandidatesList}
            setSearchValue={setSearchValue}
          />
          <div className="flex sm:flex-row flex-col items-center w-full h-full">
            <div
              className={classJoin(
                menu.isMenuOpened ? "w-64" : "w-36",
                "hidden sm:block sm:mr-8 h-full sm:self-start self-center sm:mb-0 mb-5",
              )}
            >
              <SectionTabs
                tabs={tabs}
                collapsed={!menu.isMenuOpened}
                onClickCollapse={(): AnyAction =>
                  setMenu(dispatch, menu.isMenuOpened ? "hide" : "open")
                }
                selected={menu.selectedTab}
                defaultWidth="250px"
              />
            </div>
            {dataToDisplay.length ? (
              <ScrollableWrapper value={0}>
                <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
                  <div
                    className={classJoin.template`
                      flex flex-wrap gap-5 w-full px-2 md:mx-0
                      rounded-md lg:pl-4 md:justify-start justify-center`}
                  >
                    {dataToDisplay.map(
                      (candidate: PublicFolderCandidateModel): ReactElement => (
                        <Person
                          candidate={candidate}
                          data={publicFolder}
                          key={candidate.userId}
                        />
                      ),
                    )}
                  </div>
                </Scrollbars>
              </ScrollableWrapper>
            ) : (
              <NoUsersFound />
            )}
          </div>
          <MenuIcon
            className={classJoin(
              "text-white absolute p-2 rounded-md sm:hidden top-7 left-7 w-10 h-10",
              isMobileMenuOpened ? "bg-primary-10" : "",
            )}
            onClick={(): void =>
              setIsMobileMenuOpened((prev: boolean): boolean => !prev)
            }
          />
          {isMobileMenuOpened ? (
            <div className="absolute sm:hidden bg-neutralPrimary-100 w-max h-full">
              <SectionTabs
                tabs={tabs}
                selected={menu.selectedTab}
                additionalClassName="flex flex-col gap-5 mt-5"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <LoadingMainPage additionalClassName="bg-neutralPrimary-85 mt-24 lg:mt-40" />
      )}
    </section>
  );
};

export default PublicFolder;
