import { removeDiacriticsFromString } from "@ps/utils";
import {
  ExtendedPublicFolderCandidateModel,
  PublicFolderCandidateModel,
} from "../../models/types";
import { ALL_PROFILES, COUNTRY, PROFESSION, SENIORITY } from "../../constants";
import { ISelectedFilters } from "../types";

export const prepareExtendedCandidatesList = (
  candidates: PublicFolderCandidateModel[],
): ExtendedPublicFolderCandidateModel[] =>
  candidates
    .filter(
      (candidate: PublicFolderCandidateModel): boolean => !candidate.deleted,
    )
    .map((single: PublicFolderCandidateModel) => ({
      ...single,
      noForeignCharsFullName: removeDiacriticsFromString(
        `${single?.firstName} ${single?.lastName}`?.toLowerCase() || "",
      ),
    }));

export const filterCandidates = (
  status: string,
  candidates: ExtendedPublicFolderCandidateModel[],
): ExtendedPublicFolderCandidateModel[] =>
  candidates?.filter(
    (candidate: ExtendedPublicFolderCandidateModel): boolean =>
      status === ALL_PROFILES ||
      candidate?.status[candidate?.status?.length - 1]?.acceptance === status,
  ) || [];

export const filterCandidatesByProperty = (
  baseCandidatesList: ExtendedPublicFolderCandidateModel[],
  filters: ISelectedFilters[],
): ExtendedPublicFolderCandidateModel[] =>
  baseCandidatesList?.filter(
    (candidate: PublicFolderCandidateModel): boolean =>
      (!filters[SENIORITY].length ||
        filters[SENIORITY].includes(candidate?.[SENIORITY])) &&
      (!filters[PROFESSION].length ||
        filters[PROFESSION].includes(candidate?.professions?.[0])) &&
      (!filters[COUNTRY].length ||
        filters[COUNTRY].includes(candidate?.[COUNTRY])),
  );
