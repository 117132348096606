import { isAPIError } from "@ps/api-utils";
import { AnyAction } from "redux";
import ActionTypes from "./actionTypes";
import { IError } from "./types";

const initState: IError | Record<string, unknown> = {};

function errorReducer(
  state = initState,
  action: AnyAction,
): IError | Record<string, unknown> {
  if (isAPIError(action.payload)) {
    return {
      response: action.payload,
      type: action.type,
    };
  }
  if (action.type === ActionTypes.CLEAR_ERROR) {
    return {};
  }

  return state;
}

export default errorReducer;
