import moment from "moment-timezone";
import { formatHoursAndMinutes } from "@ps/utils";
import { APITimeRanges, HoursModel } from "./types";

// TODO - finish later - when BE send candidate's zone - we need compare need and time zone
// TODO there should be displayed candidates hours in client's timezone
const mapHourToProperTimezone = (time?: string, zone?: string): string => {
  if (!time) return "";
  if (!zone) return moment.utc(time, "h:mm A").format("HH:mm");

  return moment.utc(time, "h:mm A").tz(zone).format("HH:mm");
};

// eslint-disable-next-line import/prefer-default-export
export const prepareTimeRanges = (timeRanges: APITimeRanges): HoursModel => {
  if (timeRanges) {
    // TODO in case of no zone we try to guess browser zone
    const needTimezone = timeRanges.needTimezone || moment.tz.guess();
    return {
      possible: {
        start: mapHourToProperTimezone(timeRanges.possible?.start),
        end: mapHourToProperTimezone(timeRanges.possible?.end),
      },
      comfortable: {
        start: mapHourToProperTimezone(timeRanges.comfortable?.start),
        end: mapHourToProperTimezone(timeRanges.comfortable?.end),
      },
      need: {
        start: formatHoursAndMinutes(timeRanges.need?.start),
        end: formatHoursAndMinutes(timeRanges.need?.end),
      },
      zone: {
        value: needTimezone,
        label: `${moment.tz(needTimezone).format("Z z")} (${needTimezone})`,
        abbreviation: moment.tz(needTimezone).zoneAbbr(),
      },
    };
  }

  return {};
};
