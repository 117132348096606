import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ReactElement, Suspense } from "react";
import { LoadingMainPage, Page404 } from "@ps/ui-components";
import { PAGE_404_PUBLIC_FOLDER } from "./shared/data-cy";
import PublicFolder from "./public-folder-domain";
import { CandidateProfile } from "./public-profile-domain";
import AppContainer from "./ui";

const App = (): ReactElement => (
  <Suspense fallback={<div>Loading...</div>}>
    <Router
      getUserConfirmation={(message, callback) => {
        /* eslint-disable no-alert */
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <AppContainer>
        <Switch>
          <Route exact path="/">
            <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
          </Route>
          <Route path="/:id/candidate/:candidateId">
            <CandidateProfile />
          </Route>
          <Route path="/:id">
            <PublicFolder />
          </Route>
          <Route>
            <Page404
              dataCy={PAGE_404_PUBLIC_FOLDER}
              variant="client"
              path="/"
            />
          </Route>
        </Switch>
      </AppContainer>
    </Router>
  </Suspense>
);

export default App;
