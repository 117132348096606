import { PublicFolderCandidateModel } from "../../public-folder-domain/models/types";

const getCandidatesFilterValues = (
  candidates: PublicFolderCandidateModel[],
  field: "profession" | "seniority" | "country",
): string[] => [
  ...new Set<string>(
    candidates
      ?.map((candidate: PublicFolderCandidateModel): string =>
        field === "profession"
          ? candidate?.professions?.[0] || ""
          : candidate?.[field] || "",
      )
      .flat()
      .filter((value: string | undefined) => value),
  ),
];

export default getCandidatesFilterValues;
