import { ReactElement, useState } from "react";
import { useDispatch } from "redux-react-hook";
import {
  Avatar,
  Button,
  Icon,
  Label,
  SearchSelect,
  Modal2,
} from "@ps/ui-components";
import {
  AVATAR,
  REJECTED,
  REJECT_USER,
  REJECTION_REASON,
  REJECTION_SELECT,
} from "../../constants";
import { PROPOSAL_PUBLIC_FOLDER } from "../../../shared/data-cy";
import { ReactComponent as AvatarIcon } from "../../../images/avatar.svg";
import {
  useTranslationWithNamespace,
  useMappedStateSelector,
} from "../../../hooks";
import { DictionaryEntryModel } from "../../../dictionaries";
import PublicFolderService from "../../services/publicFolder";
import { Acceptance } from "../../api/openapi-client";
import { fetchPublicFolder } from "../../store/actions";
import {
  RejectModalProps,
  RejectModalMapState,
  RejectModalMapStateReturn,
} from "./types";

const BASE_PATH = "publicFolder";

const RejectModal = ({
  candidate,
  isModalOpen,
  setIsModalOpen,
}: RejectModalProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();

  const [reason, setReason] = useState<string>("");
  const [reasonError, setReasonError] = useState<boolean>(false);

  const mapState = (state: RejectModalMapState): RejectModalMapStateReturn => ({
    seniorityDict: state.dictionaries?.seniority || [],
    publicFolder: state.publicFolder,
  });

  const { seniorityDict, publicFolder } = useMappedStateSelector(mapState);

  const reasons = [...Array(4)].map((_, index: number) => ({
    value: t(`${BASE_PATH}.reasons.reason${index}`),
    label: t(`${BASE_PATH}.reasons.reason${index}`),
  }));

  const handleOnClickReject = async (): Promise<void> => {
    if (!reason) {
      return setReasonError(true);
    }
    await PublicFolderService.updateCandidateStatus(
      publicFolder.id,
      candidate.userId,
      publicFolder.pin,
      {
        status: { acceptance: Acceptance.Rejected, feedback: reason || "" },
        favorited: false,
      },
    );
    fetchPublicFolder(dispatch, publicFolder.id, publicFolder.pin);
    return setIsModalOpen(false);
  };

  return (
    <Modal2
      isOpen={isModalOpen}
      withCloseIcon
      onClose={(): void => {
        setReasonError(false);
        setReason("");
        setIsModalOpen(false);
      }}
      withOutsideClick
      dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REJECT_USER}`}
      width="w-11/12 lg:w-1/3"
      height="h-max"
      withYScroll={false}
    >
      <div className="flex flex-col gap-8">
        <div className="flex gap-3 items-center">
          {candidate?.avatar ? (
            <Avatar
              image={candidate?.avatar}
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${AVATAR}`}
              width={24}
              height={24}
            />
          ) : (
            <Icon
              icon={<AvatarIcon className="text-neutralSecondary-60" />}
              dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${AVATAR}`}
              size="30"
            />
          )}
          <div className="flex flex-col gap-3">
            <span className="text-primary-50 font-semibold capitalize">
              {seniorityDict.find(
                (item: DictionaryEntryModel): boolean =>
                  item.id === candidate.seniority,
              )?.name || ""}
            </span>
            <span className="text-neutralPrimary-20 font-semibold">
              {`${candidate?.firstName} ${candidate?.lastName}`}
            </span>
          </div>
        </div>
        <Label
          dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REJECTION_REASON}`}
          text={`${t(`${BASE_PATH}.${REJECTION_REASON}`)}*`}
        >
          <SearchSelect
            dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REJECTION_SELECT}`}
            width="w-full"
            height="auto"
            options={reasons}
            onChange={(item): void => {
              setReason(item.value);
              setReasonError(false);
            }}
            placeholder={`${t(`${BASE_PATH}.${REJECTION_REASON}`)}*`}
            error={reasonError}
            additionalClassName="w-full"
            message={t(`${BASE_PATH}.reasonError`)}
            menuPosition="fixed"
          />
        </Label>
        <Button
          dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REJECTED}`}
          onClick={handleOnClickReject}
        >
          {t(`${BASE_PATH}.${REJECTED}`)}
        </Button>
      </div>
    </Modal2>
  );
};

export default RejectModal;
