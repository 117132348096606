import APIService from "../api/dictionaries-api";
import fromAPIToEntryModel from "../models/converters/fromAPIToEntryModel";
import { handleError } from "../../shared/utils/service-utils";

const DictionariesService = (() => {
  const fetchDictionary = async (id) => {
    try {
      const response = await APIService.API.getDictEntries(id);
      if (response?.data) {
        return fromAPIToEntryModel(response.data);
      }
      return [];
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    fetchDictionary,
  };
})();

export default DictionariesService;
