// eslint-disable-next-line prodigal-son/no-use-translation
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../shared/constants";

// eslint-disable-next-line
function useTranslationWithNamespace(namespace = NAMESPACE) {
  // eslint-disable-next-line prodigal-son/no-use-translation
  const { t } = useTranslation(namespace);
  return { t };
}

export default useTranslationWithNamespace;
