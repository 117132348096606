import { ReactElement } from "react";
import { CardHeader } from "../../../expanded-card";
import { ReactComponent as SeniorityIcon } from "../../../../images/profile-summary/seniority.svg";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../../shared/data-cy";
import Label from "../../../label";
import { useTranslationWithNamespace } from "../../../../hooks";

const BASE_PATH = "profileSummary";

const Seniority = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      additionalClassName="h-full"
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_seniority`}
      Icon={SeniorityIcon}
      iconColor="text-primary-50"
    >
      <Label
        additionalClassName="h-auto"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_seniority`}
        text={t(`${BASE_PATH}.seniority`)}
      >
        <>{children}</>
      </Label>
    </CardHeader>
  );
};

export default Seniority;
