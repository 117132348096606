import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  UI_COMPONENT_NAMESPACE,
  uiComponentTranslationEN,
} from "@ps/ui-components";
import translationEN from "i18n/en";
import { IS_PRODUCTION, NAMESPACE } from "../shared/constants";
import { CURRENT_LANGUAGE_CODE } from "./constants";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !IS_PRODUCTION,
    fallbackLng: CURRENT_LANGUAGE_CODE,
    interpolation: {
      escapeValue: false, // not needed for resrc/app/main/main.tsxact as it escapes by default
    },
    resources: {
      en: {
        [NAMESPACE]: translationEN,
        [UI_COMPONENT_NAMESPACE]: uiComponentTranslationEN,
      },
    },
    defaultNS: "user",
  });

export default i18n;
