import { ReactElement } from "react";
import {
  classJoin,
  getNameFromDictionary,
  getNamesFromDictionary,
  getOverallExperience,
} from "@ps/utils";
import {
  SummaryExperience,
  SummaryProfessions,
  SummaryRoles,
  SummarySeniority,
} from "@ps/ui-components";
import { uid } from "react-uid";
import { ExperienceSectionProps } from "./types";

const padding = "py-6 px-10";

const ExperienceSection = ({
  careerSpan,
  professions,
  roleDict,
  projectRoles,
  seniority,
  seniorityDict,
  professionsDict,
}: ExperienceSectionProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const overallExperience = getOverallExperience(careerSpan);

  const renderRoles = (): ReactElement =>
    projectRoles?.length ? (
      <SummaryRoles
        roles={getNamesFromDictionary(projectRoles, roleDict)
          .split(",")
          .filter((item) => !!item)}
      />
    ) : (
      <></>
    );

  const renderProfessions = (): ReactElement =>
    professions?.main || professions?.secondary ? (
      <SummaryProfessions>
        <div className="flex flex-col">
          <span className="font-extrabold">
            {getNameFromDictionary(professionsDict, professions?.main)}
          </span>
          {professions?.secondary?.map((item, index) => (
            <span className="font-extrabold" key={uid(`${index}-bigScreen`)}>
              {getNameFromDictionary(professionsDict, item)}
            </span>
          ))}
        </div>
      </SummaryProfessions>
    ) : (
      <></>
    );

  const renderOverAllExperience = (): ReactElement =>
    overallExperience?.years || overallExperience?.months ? (
      <SummaryExperience overallExperience={overallExperience} />
    ) : (
      <></>
    );

  const renderSeniority = (): ReactElement =>
    seniority ? (
      <SummarySeniority>
        <span className="capitalize">
          {getNameFromDictionary(seniorityDict, seniority)}
        </span>
      </SummarySeniority>
    ) : (
      <></>
    );

  return (
    <div
      className={classJoin(
        "h-full w-full bg-neutralPrimary-85 bg-opacity-50 flex gap-x-28 justify-start items-center",
        padding,
      )}
    >
      <div className="hidden md:flex h-full w-full justify-start items-center gap-32">
        {renderSeniority()}
        {renderProfessions()}
        {renderRoles()}
        {renderOverAllExperience()}
      </div>
      <div className="h-full w-full flex md:hidden flex-col justify-start flex-wrap">
        <div className="flex gap-8 items-center flex-row pb-6 flex-wrap">
          {renderSeniority()}
          {renderProfessions()}
        </div>
        <div className="flex items-center gap-8 border-t py-6 flex-wrap flex-row">
          {renderRoles()}
          {renderOverAllExperience()}
        </div>
      </div>
    </div>
  );
};
export default ExperienceSection;
