import { AnyAction } from "redux";
import * as ActionTypes from "./actionTypes";
import { PublicProfileNeedModel } from "../models/types";

const publicProfilesReducer = (
  state: PublicProfileNeedModel | Record<string, unknown> = {},
  action: AnyAction,
): PublicProfileNeedModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_PUBLIC_PROFILE_START:
      return state;
    case ActionTypes.FETCH_PUBLIC_PROFILE_SUCCESS:
      return { ...state, ...action.payload };
    case ActionTypes.FETCH_PUBLIC_PROFILE_ERROR:
      return {};
    default:
      return state;
  }
};

export default publicProfilesReducer;
