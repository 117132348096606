import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../hooks";
import { ReactComponent as NoResultIcon } from "../../images/noResult.svg";

const NoUsersFound = (): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <div className="flex items-center justify-center w-full text-neutralPrimary-20 px-10">
      <NoResultIcon />
      <span className="whitespace-nowrap">{t("publicFolder.noResult")}</span>
    </div>
  );
};

export default NoUsersFound;
