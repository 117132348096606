import { ChangeEvent, KeyboardEvent, ReactElement, useState } from "react";
import { classJoin } from "@ps/utils";
import { Button, Input, useThemeMode } from "@ps/ui-components";
import { useDispatch } from "redux-react-hook";
import { PROPOSAL_PUBLIC_FOLDER } from "../../../shared/data-cy";
import {
  COUNTRY,
  PROFESSION,
  PUBLIC_FOLDER_PATH,
  SEARCH,
  SENIORITY,
} from "../../constants";
import { focusVisibleStyles, Keys } from "../../../shared";
import { ReactComponent as FunnelIcon } from "../../../images/funnel.svg";
import { resetCandidatesByPropertyFilters } from "../../store/actions";
import { ReactComponent as BroomIcon } from "../../../images/broom.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import Filters from "./filters";
import {
  SearchFilterRowProps,
  SearchFiltersMapState,
  SearchFiltersMapStateReturn,
} from "../types";
import getCandidatesFilterValues from "../../../shared/utils/candidates-filter-values";

const SearchFilterRow = ({
  baseCandidatesList,
  setSearchValue,
}: SearchFilterRowProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast } = useThemeMode();
  const [isMobileFiltersExpanded, setIsMobileFiltersExpanded] =
    useState<boolean>(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState<
    "seniority" | "profession" | "country" | ""
  >("");

  const dispatch = useDispatch();

  const mapState = (
    state: SearchFiltersMapState,
  ): SearchFiltersMapStateReturn => ({
    areFiltersChecked: Object.entries(state.filters).some(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => value?.length,
    ),
    seniorityDict: state.dictionaries?.seniority || [],
    professionDict: state.dictionaries?.profession || [],
  });

  const { professionDict, seniorityDict, areFiltersChecked } =
    useMappedStateSelector(mapState);

  const professionsFilterOptions: string[] = getCandidatesFilterValues(
    baseCandidatesList,
    PROFESSION,
  );
  const seniorityFilterOptions: string[] = getCandidatesFilterValues(
    baseCandidatesList,
    SENIORITY,
  );
  const countryFilterOptions: string[] = getCandidatesFilterValues(
    baseCandidatesList,
    COUNTRY,
  );

  return (
    <div
      className={classJoin(
        "flex flex-col sm:flex-row items-center gap-3 w-full bg-neutralPrimary-100 p-5 my-5 rounded-lg",
        isHighContrast && "border border-primary-60",
      )}
    >
      <div className="flex flex-row items-center gap-3 w-full">
        <Input
          dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${SEARCH}`}
          isSearch
          placeholder={t(`${PUBLIC_FOLDER_PATH}.${SEARCH}`)}
          onChange={(event: ChangeEvent<HTMLInputElement>): void =>
            setSearchValue(event.target.value)
          }
          additionalContainerClass="w-full"
        />
        <div
          className={classJoin(
            "relative sm:hidden w-8.5 h-8.5 p-2 rounded-md cursor-pointer",
            isMobileFiltersExpanded
              ? "bg-primary-50 text-primary-100"
              : "border border-neutralSecondary-60 text-neutralPrimary-50",
            focusVisibleStyles,
          )}
          role="button"
          tabIndex={0}
          onClick={(): void =>
            setIsMobileFiltersExpanded((prev: boolean): boolean => !prev)
          }
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === Keys.ENTER)
              setIsMobileFiltersExpanded((prev: boolean): boolean => !prev);
          }}
        >
          <FunnelIcon className="w-5 h-5 fill-current flex-shrink-0" />
          {areFiltersChecked ? (
            <div className="absolute right-1 top-0 bg-primary-50 w-2 h-2 rounded-full" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={classJoin(
          isMobileFiltersExpanded && window.innerWidth < 640
            ? "flex sm:hidden"
            : "hidden sm:flex",
          "flex-col sm:flex-row gap-3 items-center",
        )}
      >
        <div className="flex items-center">
          {professionsFilterOptions?.length ? (
            <Filters
              dict={professionDict}
              field={PROFESSION}
              filterOptions={professionsFilterOptions}
              setShowFilterDropdown={setShowFilterDropdown}
              showFilterDropdown={showFilterDropdown}
            />
          ) : (
            <></>
          )}
          {seniorityFilterOptions?.length ? (
            <Filters
              dict={seniorityDict}
              field={SENIORITY}
              filterOptions={seniorityFilterOptions}
              setShowFilterDropdown={setShowFilterDropdown}
              showFilterDropdown={showFilterDropdown}
            />
          ) : (
            <></>
          )}
          {countryFilterOptions?.length ? (
            <Filters
              dict={[]}
              field={COUNTRY}
              filterOptions={countryFilterOptions}
              setShowFilterDropdown={setShowFilterDropdown}
              showFilterDropdown={showFilterDropdown}
            />
          ) : (
            <></>
          )}
        </div>

        {countryFilterOptions?.length ||
        seniorityFilterOptions?.length ||
        professionsFilterOptions?.length ? (
          <Button
            dataCy={PROPOSAL_PUBLIC_FOLDER}
            onClick={(): void => {
              resetCandidatesByPropertyFilters(dispatch);
              setShowFilterDropdown("");
            }}
            disabled={!areFiltersChecked}
            additionalClass="flex items-center gap-2 whitespace-nowrap self-end sm:self-center"
          >
            <BroomIcon />
            {t(`${PUBLIC_FOLDER_PATH}.clearAll`)}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SearchFilterRow;
