import { ReactElement, KeyboardEvent } from "react";
import { classJoin } from "@ps/utils";
import { useThemeMode } from "../theme-mode-provider";
import { FontSizeSwitch } from "../font-size-provider";
import ThemeToggle from "../theme-toggle";
import { ReactComponent as Logo } from "../../images/teamyai-logo.svg";
import { ReactComponent as LogoSmall } from "../../images/teamyai-logo-small.svg";
import { ReactComponent as LogoHighContrast } from "../../images/teamyai-logo-highContrast.svg";
import { ReactComponent as LogoHighContrastSmall } from "../../images/teamyai-logo-small-high-contrast.svg";
import { ReactComponent as LeftArrow } from "../../images/arrowLeft.svg";
import { ReactComponent as EyeIcon } from "../../images/contrast-eye.svg";
import { useTranslationWithNamespace } from "../../hooks";
import { PageFolderProps } from "./types";
import { PAGE_FOLDER_PREFIX } from "../../shared/data-cy";
import { focusVisibleStyles, Keys } from "../../shared";

const PageFolder = ({
  dataCy,
  onClick,
  children,
}: PageFolderProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isHighContrast, theme, toggle, set } = useThemeMode();

  return (
    <div
      className={classJoin(
        "flex flex-col relative h-full w-full",
        isHighContrast ? "bg-primary-95" : "bg-neutralPrimary-85",
      )}
      data-cy={`${PAGE_FOLDER_PREFIX}-${dataCy}`}
    >
      <div className="w-full h-max">
        {onClick ? (
          <button
            data-cy={`${PAGE_FOLDER_PREFIX}_back-btn`}
            className={classJoin(
              "absolute mt-9 ml-5 text-white flex items-center cursor-pointer rounded-md",
              focusVisibleStyles,
            )}
            onClick={onClick}
          >
            <LeftArrow className="mt-1" />
            <span>{t("pages.back")}</span>
          </button>
        ) : (
          <></>
        )}
        <div
          className={classJoin(
            "w-full h-24 flex justify-center items-center",
            isHighContrast
              ? "bg-primary-95 border-b border-neutralPrimary-50"
              : "bg-primary-20",
          )}
        >
          {isHighContrast ? (
            <>
              <LogoHighContrast className="hidden sm:block w-40 h-20" />
              <LogoHighContrastSmall className="block sm:hidden w-10 h-10" />
            </>
          ) : (
            <>
              <Logo className="hidden sm:block w-40 h-20" />
              <LogoSmall className="block sm:hidden w-10 h-10" />
            </>
          )}
        </div>
        <div className="flex items-center gap-3 absolute z-10 top-8 right-2 sm:right-10">
          <FontSizeSwitch additionalClassName="mt-1" />
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
              if (event.key === Keys.ENTER)
                isHighContrast ? set("light") : set("highContrast");
            }}
            className={classJoin("w-7 h-7 rounded-md", focusVisibleStyles)}
          >
            <EyeIcon
              className="text-white cursor-pointer"
              onClick={(): void =>
                isHighContrast ? set("light") : set("highContrast")
              }
            />
          </div>
          {isHighContrast ? (
            <div style={{ width: "40px" }} />
          ) : (
            <ThemeToggle
              dataCy={PAGE_FOLDER_PREFIX}
              checked={theme === "dark"}
              onChange={toggle}
            />
          )}
        </div>
      </div>
      <div className="p-0 md:px-10 h-full w-full overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default PageFolder;
