export const SEARCH = "search";
export const USER_CARD = "userCard";
export const AVATAR = "avatar";
export const COUNTRY = "country";
export const OVERLAP = "overlap";
export const PROFESSION = "profession";
export const SENIORITY = "seniority";
export const AUTHORIZATION = "authorization";
export const REJECT_USER = "rejectUser";
export const REJECTION_REASON = "rejectionReason";
export const REJECTION_SELECT = "rejectionUser";
export const REVERT = "revert";

export const IN_PROGRESS = "inProgress";
export const ALL_PROFILES = "allProfiles";
export const APPROVED = "approved";
export const REJECTED = "rejected";

export const DICTIONARIES = {
  DOMAIN: "domain",
  PROFESSION: "profession",
  PROJECT_ROLE: "project_role",
  SENIORITY: "seniority",
  TECHNOLOGY: "technology",
  COUNTRY: "country",
};

export const selectedFiltersInitialState = {
  [COUNTRY]: [],
  [SENIORITY]: [],
  [PROFESSION]: [],
};

export const menuInitialState = {
  selectedTab: ALL_PROFILES,
  isMenuOpened: true,
};

export const PUBLIC_FOLDER_PATH = "publicFolder";
