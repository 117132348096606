import { classJoin } from "@ps/utils";
import { Dropdown, useFontSizeMode } from "@ps/ui-components";
import { KeyboardEvent, ReactElement } from "react";
import { focusVisibleStyles, Keys } from "../../../shared";
import { ReactComponent as FunnelIcon } from "../../../images/funnel.svg";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../../hooks";
import { FilterOptionsMapState, FiltersProps } from "../types";
import FilterOptions from "./filterOptions";
import { PUBLIC_FOLDER_PATH } from "../../constants";

const Filters = ({
  dict,
  field,
  filterOptions,
  setShowFilterDropdown,
  showFilterDropdown,
}: FiltersProps): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const { isLargeFont } = useFontSizeMode();

  const mapState = (state: FilterOptionsMapState): FilterOptionsMapState => ({
    filters: state.filters,
  });

  const { filters } = useMappedStateSelector(mapState);

  const handleOnClick = (): void => {
    const temp = showFilterDropdown === field ? "" : field;
    setShowFilterDropdown(temp);
  };

  return (
    <div
      className={classJoin(
        "flex items-center gap-2 px-1 sm:px-3 text-neutralPrimary-20",
        isLargeFont ? "flex-col px-3" : "",
      )}
    >
      <span className="font-semibold">
        {t(`${PUBLIC_FOLDER_PATH}.${field}`)}
      </span>
      <Dropdown
        withArrow
        width="w-auto"
        show={showFilterDropdown === field}
        onClickOutside={(): void => setShowFilterDropdown("")}
        overlay={
          <div className="p-4 flex flex-col gap-4 h-auto overflow-y-auto max-h-96 w-72">
            <FilterOptions options={filterOptions} field={field} dict={dict} />
          </div>
        }
      >
        <div
          className={classJoin(
            "w-8.5 h-8.5 p-2 rounded-md cursor-pointer",
            filters[field]?.length
              ? "bg-primary-50 text-primary-100"
              : "border border-neutralSecondary-60 text-neutralPrimary-50",
            focusVisibleStyles,
          )}
          role="button"
          tabIndex={0}
          onClick={handleOnClick}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === Keys.ENTER) handleOnClick();
          }}
        >
          <FunnelIcon className="w-5 h-5 fill-current flex-shrink-0" />
        </div>
      </Dropdown>
    </div>
  );
};

export default Filters;
