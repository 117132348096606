const prepareCareerPauseModel = (experience?: number) => {
  if (!experience) return { years: 0, months: 0 };

  const years = parseInt(String(experience / 12), 10);
  const months = parseInt(String(experience % 12), 10);

  return { years, months };
};

export default prepareCareerPauseModel;
