import { AnyAction } from "redux";
import ActionTypes from "../../store/actionTypes";
import { PublicFolderModel } from "../models/types";
import { ISelectedFilters, IMenu } from "../../store/types";
import { selectedFiltersInitialState, menuInitialState } from "../constants";

const publicFolderReducer = (
  state: PublicFolderModel | Record<string, unknown> = {},
  action: AnyAction,
): PublicFolderModel | Record<string, unknown> => {
  switch (action.type) {
    case ActionTypes.FETCH_PUBLIC_FOLDER_START:
      return state;
    case ActionTypes.FETCH_PUBLIC_FOLDER_SUCCESS:
      return action.payload;
    case ActionTypes.FETCH_PUBLIC_FOLDER_ERROR:
      return {};
    default:
      return state;
  }
};

export const filtersReducer = (
  state: ISelectedFilters = selectedFiltersInitialState,
  action: AnyAction,
): ISelectedFilters => {
  const optionId: string = action?.payload?.optionId;
  const filterName: string = action?.payload?.filterName;
  switch (action.type) {
    case ActionTypes.ADD_FILTER:
      return {
        ...state,
        [filterName]: [...state[filterName], optionId],
      };
    case ActionTypes.REMOVE_FILTER:
      return {
        ...state,
        [filterName]: state[filterName].filter(
          (item: string): boolean => item !== optionId,
        ),
      };
    case ActionTypes.RESET_FILTERS:
      return selectedFiltersInitialState;
    default:
      return state;
  }
};

export const menuReducer = (
  state: IMenu = menuInitialState,
  action: AnyAction,
): IMenu => {
  switch (action.type) {
    case ActionTypes.HIDE_MENU:
      return { ...state, isMenuOpened: false };
    case ActionTypes.OPEN_MENU:
      return { ...state, isMenuOpened: true };
    case ActionTypes.SET_CURRENT_TAB:
      return { ...state, selectedTab: action.payload };
    default:
      return state;
  }
};

export default publicFolderReducer;
