import { APIService as PublicFolderAPIService } from "../public-folder-domain";
import { APIService as PublicProfileAPIService } from "../public-profile-domain";
import { APIService as PublicDictionariesAPIService } from "../dictionaries";

const setPINAuth = (authData: { password: string; username: string }): void => {
  PublicFolderAPIService.setConfig(authData);
  PublicProfileAPIService.setConfig(authData);
  PublicDictionariesAPIService.setConfig(authData);
};

export default setPINAuth;
