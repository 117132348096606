import { getName } from "i18n-iso-countries";
import { ISOToDayJs } from "@ps/utils";
import { CURRENT_LANGUAGE_CODE } from "../../../i18n/constants";
import {
  PublicFolderAPIModel,
  PublicFolderModel,
  PublicFolderCandidateAPIModel,
  PublicFolderCandidateModel,
} from "../types";

// eslint-disable-next-line import/prefer-default-export
export const fromAPIToModel = (
  data: PublicFolderAPIModel,
): PublicFolderModel => ({
  id: data.id,
  pin: data.pin,
  candidates: data.candidates.map(
    (candidate: PublicFolderCandidateAPIModel): PublicFolderCandidateModel => ({
      avatar: candidate.avatar,
      country: candidate?.country
        ? getName(candidate.country, CURRENT_LANGUAGE_CODE, {
            select: "official",
          })
        : "",
      createdAt: ISOToDayJs(candidate.createdAt),
      deleted: candidate.deleted,
      favourited: candidate.favorited,
      firstName: candidate?.firstName,
      lastName: candidate?.lastName || "",
      professions: candidate?.professions,
      seniority: candidate?.seniority || "",
      status: candidate.status,
      userId: candidate.userId,
    }),
  ),
  expirationDate: ISOToDayJs(data.expirationDate),
  lastUpdate: ISOToDayJs(data.lastUpdate),
  anonymized: data.anonymized,
  archived: data.archived,
});
