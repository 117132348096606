export default {
  publicFolder: {
    overlap: "Overlap",
    more: "More",
    search: "Search",
    rejectionReason: "Reason",
    approved: "Interested",
    rejected: "Not interested",
    allProfiles: "All profiles",
    reasons: {
      reason0: "Not enough experience",
      reason1: "Not enough domain knowledge",
      reason2: "Not enough time overlap",
      reason3: "Rate is too high",
    },
    reasonError: "Choose reason",
    noResult: "No users found",
    country: "Location",
    profession: "Professions",
    seniority: "Seniority",
    clearStatus: "Clear status",
    clearAll: "Clear all",
    usersProfile: "User's profile",
    info1:
      "Here with these two buttons, you can choose if the profile fits your project.",
    the: "The",
    info2: "button means that you are not interested in this candidate.",
    info3:
      "means that this user might be a right fit for challenges on the project.",
    info4: "At any moment, you can always change your decision.",
    gotIt: "Got it!",
  },
  publicProfile: {
    seniority: "Seniority",
  },
  notFoundPage: {
    code: "404",
    header: "page not found",
    content:
      "We are sorry, but you have no access to this page. Please check if you received correct address to the folder.",
  },
};
