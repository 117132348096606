import APIService from "../api/public-folder-api";
import { fromAPIToModel, toAPIModel } from "../models";
import { PublicFolderUpdateCandidateStatusModel } from "../models/types";
import { handleError } from "../../shared/utils/service-utils";

const PublicFolderService = (() => {
  const fetchPublicFolder = async (folderId: string, pin: string) => {
    try {
      APIService.setConfig({ username: folderId, password: pin });
      const response = await APIService.API.getFolder();

      if (response?.data) {
        return fromAPIToModel(response.data);
      }
      return [];
    } catch (err) {
      return handleError(err);
    }
  };

  const updateCandidateStatus = async (
    folderId: string,
    userId: string,
    pin: string,
    updatedStatus: PublicFolderUpdateCandidateStatusModel,
  ) => {
    try {
      return await APIService.API.updateCandidateStatus(
        userId,
        toAPIModel(updatedStatus),
      );
    } catch (err) {
      return handleError(err);
    }
  };

  return {
    fetchPublicFolder,
    updateCandidateStatus,
  };
})();

export default PublicFolderService;
