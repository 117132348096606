import { ReactElement, useEffect, useState } from "react";
import { AnyAction } from "redux";
import { useDispatch } from "redux-react-hook";
import { useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { motion } from "framer-motion";
import {
  Card,
  LoadingMainPage,
  SummaryBaseInfo,
  SummaryLanguages,
  SummaryOtherExperience,
  SummaryProjects,
  SummarySkills,
  SummaryTimeline,
  ScrollableWrapper,
  Button,
  Tooltip,
} from "@ps/ui-components";
import {
  InfoModal,
  RejectModal,
  APPROVED,
  REJECTED,
  REVERT,
  IN_PROGRESS,
  PublicFolderService,
  Acceptance,
  fetchPublicFolder,
  PublicFolderCandidateModel,
} from "../../public-folder-domain";
import { PROPOSAL_PUBLIC_FOLDER } from "../../shared/data-cy";
import { ReactComponent as ApprovedIcon } from "../../images/approved.svg";
import { ReactComponent as RejectedIcon } from "../../images/rejected.svg";
import { ReactComponent as NeutralIcon } from "../../images/neutral.svg";
import { CANDIDATE_SUMMARY, languageLevelsDict } from "../constants";
import {
  useMappedStateSelector,
  useTranslationWithNamespace,
} from "../../hooks";
import ExperienceSection from "./experienceSection";
import { prepareOtherExperience } from "./helpers/helpers";
import { fetchPublicProfile } from "../store/actions";
import {
  CandidateProfileMapState,
  CandidateProfileMapStateReturn,
} from "./types";

const BASE_PATH = "publicFolder";

const CandidateProfile = (): ReactElement => {
  const { t } = useTranslationWithNamespace();
  const dispatch = useDispatch();
  const { candidateId } = useParams<{ candidateId: string }>();

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

  const mapState = (
    state: CandidateProfileMapState,
  ): CandidateProfileMapStateReturn => ({
    publicProfiles: state.publicProfiles || [],
    professionsDict: state.dictionaries?.profession || [],
    roleDict: state.dictionaries?.project_role || [],
    seniorityDict: state.dictionaries?.seniority || [],
    technologyDict: state.dictionaries.technology || [],
    domainsDict: state.dictionaries?.domain || [],
    publicFolder: state.publicFolder,
  });

  const {
    publicProfiles,
    seniorityDict,
    professionsDict,
    roleDict,
    technologyDict,
    domainsDict,
    publicFolder,
  } = useMappedStateSelector(mapState);

  const candidateProfile = publicProfiles?.[candidateId || ""];

  const handleOnFetchCandidateProfile = async (id: string): Promise<void> => {
    if (!Object.keys(publicProfiles)?.length) setInfoModalOpen(true);
    await fetchPublicProfile(dispatch, id);
  };

  useEffect(() => {
    if (!candidateProfile && candidateId) {
      handleOnFetchCandidateProfile(candidateId);
    }
  }, []);

  const otherExperience = candidateProfile?.experience
    ? prepareOtherExperience(candidateProfile.experience)
    : {};

  const handleOnClickApprove = async (): Promise<AnyAction> => {
    await PublicFolderService.updateCandidateStatus(
      publicFolder.id,
      candidateId,
      publicFolder.pin,
      {
        status: { acceptance: Acceptance.Approved, feedback: "" },
        favorited: false,
      },
    );
    return fetchPublicFolder(dispatch, publicFolder.id, publicFolder.pin);
  };

  const handleOnClickNeutral = async (): Promise<AnyAction> => {
    await PublicFolderService.updateCandidateStatus(
      publicFolder.id,
      candidateId,
      publicFolder.pin,
      {
        status: { acceptance: Acceptance.InProgress, feedback: "" },
        favorited: false,
      },
    );
    return fetchPublicFolder(dispatch, publicFolder.id, publicFolder.pin);
  };

  const candidate: PublicFolderCandidateModel = publicFolder?.candidates?.find(
    (cand: PublicFolderCandidateModel): boolean => cand.userId === candidateId,
  );
  const candidateStatus: string =
    candidate.status[candidate.status?.length - 1]?.acceptance;

  if (!candidateProfile)
    return (
      <LoadingMainPage additionalClassName="bg-neutralPrimary-85 pt-24 lg:pt-40" />
    );

  return (
    <>
      {infoModalOpen ? (
        <InfoModal
          isModalOpen={infoModalOpen}
          setIsModalOpen={setInfoModalOpen}
        />
      ) : (
        <RejectModal
          candidate={candidate}
          isModalOpen={rejectModalOpen}
          setIsModalOpen={setRejectModalOpen}
        />
      )}

      <section
        data-cy={CANDIDATE_SUMMARY}
        className="pb-10 h-full flex flex-col gap-2 px-4"
      >
        <div className="flex items-center gap-4 self-center sm:self-end py-5">
          {candidateStatus === IN_PROGRESS ||
          candidateStatus === APPROVED ||
          !candidateStatus ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Button
                dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${APPROVED}`}
                additionalClass="p-3"
                variant="successPrimary"
                onClick={handleOnClickApprove}
                height="h-12"
                disabled={candidateStatus === APPROVED}
              >
                <span className="flex items-center gap-3">
                  <ApprovedIcon />
                  {t(`${BASE_PATH}.${APPROVED}`)}
                </span>
              </Button>
            </motion.div>
          ) : (
            <></>
          )}
          {candidateStatus === IN_PROGRESS ||
          candidateStatus === REJECTED ||
          !candidateStatus ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Button
                dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REJECTED}`}
                additionalClass="p-3"
                height="h-12"
                variant="errorPrimary"
                onClick={() => setRejectModalOpen(true)}
                disabled={candidateStatus === REJECTED}
              >
                <span className="flex items-center gap-3">
                  <RejectedIcon />
                  {t(`${BASE_PATH}.${REJECTED}`)}
                </span>
              </Button>
            </motion.div>
          ) : (
            <></>
          )}
          {candidateStatus === APPROVED || candidateStatus === REJECTED ? (
            <Tooltip
              backgroundColor="primary-100"
              textColor="neutralPrimary-50"
              additionalClassName="border border-neutralPrimary-50"
              placement="bottom"
              content={<span>{t(`${BASE_PATH}.clearStatus`)}</span>}
            >
              <Button
                dataCy={`${PROPOSAL_PUBLIC_FOLDER}_${REVERT}`}
                onClick={handleOnClickNeutral}
                variant="primaryNegative"
                height="h-12"
                width="w-12"
              >
                <NeutralIcon />
              </Button>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <ScrollableWrapper value={0}>
          <Scrollbars autoHide style={{ width: "100%", height: "100%" }}>
            {Object.keys(candidateProfile).map((item) => item)?.length ? (
              <section
                data-cy={CANDIDATE_SUMMARY}
                className="flex flex-col gap-8 pb-16 bg-transparent"
              >
                <Card
                  dataCy={`${CANDIDATE_SUMMARY}_header`}
                  additionalCardClassName="flex w-full flex-col"
                >
                  <div className="flex flex-col md:flex-row md:justify-between">
                    <SummaryBaseInfo
                      avatar={candidateProfile?.avatar}
                      bio={candidateProfile?.bio}
                      residence={candidateProfile?.residence?.label}
                      userName={`${candidateProfile?.firstName} ${candidateProfile?.lastName}`}
                    />
                    {candidateProfile.timeRanges?.comfortable?.start && (
                      <div className="w-full md:w-1/3 h-auto">
                        <SummaryTimeline {...candidateProfile?.timeRanges} />
                      </div>
                    )}
                  </div>
                  <ExperienceSection
                    {...candidateProfile}
                    roleDict={roleDict}
                    seniorityDict={seniorityDict}
                    professionsDict={professionsDict}
                  />
                </Card>
                {candidateProfile?.languages?.length ? (
                  <Card dataCy={`${CANDIDATE_SUMMARY}_languages`}>
                    <SummaryLanguages
                      languages={candidateProfile?.languages}
                      languageLevelsDict={languageLevelsDict}
                    />
                  </Card>
                ) : (
                  <></>
                )}
                {candidateProfile?.skills?.best?.length ||
                candidateProfile?.skills?.byProfession?.length ? (
                  <SummarySkills
                    mainProfession={candidateProfile.professions?.main}
                    professionsDict={professionsDict}
                    skills={candidateProfile.skills}
                    technologyDict={technologyDict}
                    domains={candidateProfile.domains}
                    domainsDict={domainsDict}
                  />
                ) : (
                  <></>
                )}
                {candidateProfile?.experience?.projects?.length ? (
                  <SummaryProjects
                    projectRolesDict={roleDict}
                    projects={candidateProfile.experience?.projects}
                    technologyDict={technologyDict}
                    domainsDict={domainsDict}
                  />
                ) : (
                  <></>
                )}
                <SummaryOtherExperience
                  experience={otherExperience}
                  technologyDict={technologyDict}
                />
              </section>
            ) : (
              <LoadingMainPage additionalClassName="bg-neutralPrimary-85" />
            )}
          </Scrollbars>
        </ScrollableWrapper>
      </section>
    </>
  );
};

export default CandidateProfile;
