import { ReactElement } from "react";
import { ReactComponent as RoleIcon } from "../../../../images/profile-summary/role.svg";
import { CardHeader } from "../../../expanded-card";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../../shared/data-cy";
import { useTranslationWithNamespace } from "../../../../hooks";
import Label from "../../../label";
import InfoTooltip from "../../../info-tooltip";

const BASE_PATH = "profileSummary";

const Roles = ({ roles }: { roles: string[] }): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_role`}
      Icon={RoleIcon}
      iconColor="text-primary-50"
    >
      <Label
        additionalClassName="h-auto"
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_role`}
        text={t(`${BASE_PATH}.roles`)}
      >
        <div className="flex flex-col">
          {roles.map((role) => (
            <div className="flex font-semibold items-center w-auto" key={role}>
              <span className="capitalize pr-1">{role}</span>
              <InfoTooltip
                // temporary hardcoded roles in i18n TO FIX
                tooltipContent={t(
                  `profileSummary.${role.trim().toLowerCase()}Tooltip`,
                )}
                dataCy={`${PROFILE_SUMMARY_BASE_INFO}_possibleHours`}
                iconColor="bg-primary-50"
              />
            </div>
          ))}
        </div>
      </Label>
    </CardHeader>
  );
};

export default Roles;
