import { ReactElement } from "react";
import { useTranslationWithNamespace } from "../../../../hooks";
import { CardHeader } from "../../../expanded-card";
import { PROFILE_SUMMARY_BASE_INFO } from "../../../../shared/data-cy";
import { ReactComponent as ScalacLogo } from "../../../../images/profile-summary/scalac-logo.svg";
import Label from "../../../label";
import { StartDateProps } from "../../types";

const BASE_PATH = "profileSummary";

const StartDate = ({
  Icon = ScalacLogo,
  organizationName,
  startDate,
}: StartDateProps): ReactElement => {
  const { t } = useTranslationWithNamespace();

  return (
    <CardHeader
      dataCy={`${PROFILE_SUMMARY_BASE_INFO}_joinDate`}
      // TODO  Removed hardcoded Scalac logo and name
      Icon={Icon}
      iconColor="text-primary-50"
    >
      <Label
        dataCy={`${PROFILE_SUMMARY_BASE_INFO}_joinDate`}
        text={t(`${BASE_PATH}.joinDate`, { name: organizationName })}
      >
        <span className="font-semibold">{startDate}</span>
      </Label>
    </CardHeader>
  );
};

export default StartDate;
