/* tslint:disable */
/* eslint-disable */
/**
 * Hire-help Public Folder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: contact@scalac.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const Acceptance = {
    Rejected: 'rejected',
    InProgress: 'inProgress',
    Approved: 'approved'
} as const;

export type Acceptance = typeof Acceptance[keyof typeof Acceptance];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'messages': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCandidate
 */
export interface GetCandidate {
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'seniority'?: string;
    /**
     * 
     * @type {Array<GetStatus>}
     * @memberof GetCandidate
     */
    'status': Array<GetStatus>;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'avatar'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCandidate
     */
    'professions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCandidate
     */
    'timeZone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCandidate
     */
    'favorited': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCandidate
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface GetPublicFolder
 */
export interface GetPublicFolder {
    /**
     * 
     * @type {string}
     * @memberof GetPublicFolder
     */
    'id': string;
    /**
     * A 4-digit security number
     * @type {string}
     * @memberof GetPublicFolder
     */
    'pin'?: string;
    /**
     * 
     * @type {Array<GetCandidate>}
     * @memberof GetPublicFolder
     */
    'candidates': Array<GetCandidate>;
    /**
     * A date after which the Folder will not be accessible anymore
     * @type {string}
     * @memberof GetPublicFolder
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetPublicFolder
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPublicFolder
     */
    'anonymized': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPublicFolder
     */
    'archived': boolean;
}
/**
 * The status of a Candidate, can be either \'rejected\' , \'inProgress\' or \'approved\'. Feedback needs to be provided only in the case of rejection
 * @export
 * @interface GetStatus
 */
export interface GetStatus {
    /**
     * 
     * @type {Acceptance}
     * @memberof GetStatus
     */
    'acceptance': Acceptance;
    /**
     * 
     * @type {string}
     * @memberof GetStatus
     */
    'feedback'?: string;
    /**
     * Date and time when the status was set
     * @type {string}
     * @memberof GetStatus
     */
    'setAt': string;
}


/**
 * 
 * @export
 * @interface UpdateCandidateStatus
 */
export interface UpdateCandidateStatus {
    /**
     * 
     * @type {UpdateStatus}
     * @memberof UpdateCandidateStatus
     */
    'status': UpdateStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCandidateStatus
     */
    'favorited': boolean;
}
/**
 * The status of a Candidate, can be either \'rejected\' , \'inProgress\' or \'approved\'. Feedback needs to be provided only in the case of rejection
 * @export
 * @interface UpdateStatus
 */
export interface UpdateStatus {
    /**
     * 
     * @type {Acceptance}
     * @memberof UpdateStatus
     */
    'acceptance': Acceptance;
    /**
     * 
     * @type {string}
     * @memberof UpdateStatus
     */
    'feedback'?: string;
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch Folder by its id and pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication FolderAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication TenantAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a given Candidate\'s status
         * @param {string} userId 
         * @param {UpdateCandidateStatus} updateCandidateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCandidateStatus: async (userId: string, updateCandidateStatus: UpdateCandidateStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCandidateStatus', 'userId', userId)
            // verify required parameter 'updateCandidateStatus' is not null or undefined
            assertParamExists('updateCandidateStatus', 'updateCandidateStatus', updateCandidateStatus)
            const localVarPath = `/folder/candidates/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication FolderAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication TenantAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCandidateStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch Folder by its id and pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolder(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublicFolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolder(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getFolder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a given Candidate\'s status
         * @param {string} userId 
         * @param {UpdateCandidateStatus} updateCandidateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCandidateStatus(userId: string, updateCandidateStatus: UpdateCandidateStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCandidateStatus(userId, updateCandidateStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.updateCandidateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Fetch Folder by its id and pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(options?: any): AxiosPromise<GetPublicFolder> {
            return localVarFp.getFolder(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a given Candidate\'s status
         * @param {string} userId 
         * @param {UpdateCandidateStatus} updateCandidateStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCandidateStatus(userId: string, updateCandidateStatus: UpdateCandidateStatus, options?: any): AxiosPromise<void> {
            return localVarFp.updateCandidateStatus(userId, updateCandidateStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Fetch Folder by its id and pin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFolder(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFolder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a given Candidate\'s status
     * @param {string} userId 
     * @param {UpdateCandidateStatus} updateCandidateStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCandidateStatus(userId: string, updateCandidateStatus: UpdateCandidateStatus, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCandidateStatus(userId, updateCandidateStatus, options).then((request) => request(this.axios, this.basePath));
    }
}



