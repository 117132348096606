// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DefaultApi } from "./openapi-client";

const getAxiosConfig = () => ({
  isJsonMime: () => false,
  basePath: process?.env?.REACT_APP_FOLDER_URL || "",
  apiKey: window.location.host.split(".")[0] || "",
});

class APIService {
  public API: DefaultApi;

  constructor() {
    this.API = new DefaultApi(getAxiosConfig());
  }

  public setConfig(newConfig) {
    this.API = new DefaultApi({ ...getAxiosConfig(), ...newConfig });
  }
}

export default new APIService();
